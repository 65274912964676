import canvg from "canvg";

var SunCalc = require('suncalc');
var d3 = require("d3");

export default class DaylightMap {
    constructor(date, options = {}) {

        const svg = document.getElementById('earth-day-night');
        const canvas = document.getElementById('earth-day-night-canvas');

        const context = canvas.getContext('2d');

        context.clearRect(0, 0, canvas.width, canvas.height);
        svg.innerHTML = ""
//svg.parentNode.removeChild(svg);
        this.options = {
            tickDur: options.tickDur || 400,
            shadowOpacity: options.shadowOpacity || 1,
            bgColorLeft: options.bgColorLeft || '#ffffff',
            bgColorRight: options.bgColorRight || '#ffffff',
            lightsColor: options.lightsColor || '#000000',
            lightsOpacity: options.lightsOpacity || 0,
            sunOpacity: options.sunOpacity || 0
        };
        this.PRECISION_LAT = 1; // How many latitudinal degrees per point when checking solar position.
        this.PRECISION_LNG = 10; // How may longitudial degrees per sunrise / sunset path point.
        this.MAP_WIDTH = 2048;
        this.MAP_HEIGHT = this.MAP_WIDTH / 2;
        this.SCALAR_X = this.MAP_WIDTH / 360;
        this.SCALAR_Y = this.MAP_HEIGHT / 180;
        this.svg = svg;
        this.animInterval = null;
        this.currDate = date || new Date();
    }




    isDaylight(obj) {
        return obj.altitude > 0;
    }

    isNorthSun() {
        return this.isDaylight(SunCalc.getPosition(this.currDate, 90, 0));
    }

    getSunriseSunsetLatitude(lng, northSun) {
        var delta, endLat, lat, startLat;
        if (northSun) {
            startLat = -90;
            endLat = 90;
            delta = this.PRECISION_LAT;
        } else {
            startLat = 90;
            endLat = -90;
            delta = -this.PRECISION_LAT;
        }
        lat = startLat;
        while (lat !== endLat) {
            if (this.isDaylight(SunCalc.getPosition(this.currDate, lat, lng))) {
                return lat;
            }
            lat += delta;
        }
        return lat;
    }


    getAllSunriseSunsetCoords(northSun) {
        var coords, lng;
        lng = -180;
        coords = [];
        while (lng < 180) {
            coords.push([this.getSunriseSunsetLatitude(lng, northSun), lng]);
            lng += this.PRECISION_LNG;
        }
        // Add the last point to the map.
        coords.push([this.getSunriseSunsetLatitude(180, northSun), 180]);
        return coords;
    }

    getImageFromCanvas() {
        return document.getElementById("earth-day-night-canvas").toDataURL()
    }


    svgToCanvas() {
        canvg(document.getElementById("earth-day-night-canvas"), document.getElementById("earth-day-night").outerHTML)

    }

    coordToXY(coord) {
        var x, y;
        x = (coord[1] + 180) * this.SCALAR_X;
        y = this.MAP_HEIGHT - (coord[0] + 90) * this.SCALAR_Y;
        return {
            x: x,
            y: y
        };
    }


    getPath(northSun) {
        var coords, path;
        path = [];
        coords = this.getAllSunriseSunsetCoords(northSun);
        coords.forEach((val) => {
            return path.push(this.coordToXY(val));
        });
        return path;
    }

    getPathString(northSun) {
        let path, pathStr, yStart;
        if (!northSun) {
            yStart = 0;
        } else {
            yStart = this.MAP_HEIGHT;
        }
        pathStr = `M 0 ${yStart}`;
        path = this.getPath(northSun);
        pathStr += this.lineFunction(path);
        // Close the path back to the origin.
        pathStr += ` L ${this.MAP_WIDTH}, ${yStart} `;
        pathStr += ` L 0, ${yStart} `;

        return pathStr;
    }

    createDefs() {
        d3.select(this.svg).append('defs').append('linearGradient').attr('id', 'gradient').attr('x1', '0%').attr('y1', '0%').attr('x2', '100%').attr('y2', '0%');
        d3.select(this.svg).select('defs').append('linearGradient').attr('id', 'landGradient').attr('x1', '0%').attr('y1', '0%').attr('x2', '100%').attr('y2', '0%');
        d3.select(this.svg).select('defs').append('radialGradient').attr('id', 'radialGradient');
        d3.select('#radialGradient').append('stop').attr('offset', '0%').attr('stop-opacity', this.options.sunOpacity).attr('stop-color', "rgb(255, 255, 255)");
        return d3.select('#radialGradient').append('stop').attr('offset', '100%').attr('stop-opacity', 0).attr('stop-color', 'rgb(255, 255, 255)');
    }

    drawSVG() {
        return d3.select(this.svg).attr('width', this.MAP_WIDTH).attr('height', this.MAP_HEIGHT).attr('viewBox', `0 0 ${this.MAP_WIDTH} ${this.MAP_HEIGHT}`).append('rect').attr('width', this.MAP_WIDTH).attr('height', this.MAP_HEIGHT).attr('fill', "url(#gradient)");
    }


    drawPath() {
        const path = this.getPathString(this.isNorthSun());
        return d3.select(this.svg).append('path').attr('id', 'nightPath').attr('fill', "rgb(0,0,0)").attr('fill-opacity', this.options.shadowOpacity).attr('d', path);
    }



    drawAll() {
        this.drawSVG();
        this.createDefs();
        this.drawPath();
    }


    stop() {
        return clearInterval(this.animInterval);
    }

    init() {
        this.drawAll();

    }

};

DaylightMap.prototype.lineFunction = d3.svg.line().x(function (d) {
    return d.x;
}).y(function (d) {
    return d.y;
}).interpolate('basis');
