import React from "react";
import {Dropdown} from "semantic-ui-react";

type Props = {
    value: any;
    updateField: (fieldId: string, value: any) => void;
    fieldId: string;
}
export default class Select extends React.Component<Props> {


    render() {
        const {value, updateField, fieldId} = this.props;

        return (

            <div style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }} className={"input-section"}>
                <div className="input-section-child" style={{overflow: 'visible'}}>
                    <label className="registration-form-label">Size</label>
                    <Dropdown fluid defaultValue={value.value}
                              onChange={(val, e) => updateField(fieldId, getOption(e.value))} selection
                              options={options}
                    />
                </div>
            </div>
        )
    }

}


function getOption(val: any) {

    let option = null
    options.some((opt) => {
            if (opt.value == val) {
                option = opt
                return true
            }
            return false
        }
    )

    return option
}

const options = [
    {
        key: '12x18',
        text: '12" x 18" - $49.99',
        value: '12x18',
        price: 49.99,
        display: '12" x 18"'
    },
    {
        key: '20x30',
        text: '20" x 30" - $59.99',
        value: '20x30',
        price: 59.99,
        display: '20" x 30"'
    },
    {
        key: '24x36',
        text: '24" x 36" - $69.99',
        value: '24x36',
        price: 69.99,
        display: '24" x 36"'
    }
]