import React from "react";
import PropTypes from "prop-types";
import {Icon, Label, Popup} from "semantic-ui-react";

export default class OptionsInput extends React.Component {

    static propTypes = {
        value: PropTypes.any,
        title: PropTypes.string,
        icon: PropTypes.string,
        tooltip: PropTypes.string,
        fieldId: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        updateField: PropTypes.func.isRequired
    };


    render() {
        const {title, value, updateField, fieldId, tooltip, icon} = this.props;

        return (

            <div className={"input-section label-option-wrapper ui segment "}>
                <Label basic active size="large"
                       style={{
                           boxShadow: '0 2px 4px 0 rgba(34,36,38,.12), 0 2px 10px 0 rgba(34,36,38,.15)'
                       }}>
                    {title}
                    {tooltip && <span>
                        <Popup trigger={<Icon style={{margin: 0}} name={icon}/>}
                               content={tooltip}/>
                    </span>}
                </Label>
                <div className="radio-button" id={`option-${fieldId}`}>
                    <input type="checkbox" checked={value} onChange={() => updateField(fieldId, !value)}
                           className="check"/>
                    <label className="checkLabel" onClick={() => updateField(fieldId, !value)}/>
                </div>
            </div>
        )
    }
}
