import React from "react";
import ReactDOM from "react-dom";
import "./styles/core.scss";
import "semantic-ui-css/semantic.min.css";
import * as serviceWorker from "./serviceWorker";
import {applyMiddleware, combineReducers, createStore} from "redux";
import {Provider} from "react-redux";
import reducer from "./modules/reducer";
import orderReducer from "./modules/orderReducer";
import thunk from "redux-thunk";
import Container from "./containers/AppContainer";
import * as Sentry from "@sentry/browser";
import ReactGA from "react-ga";

ReactGA.initialize('UA-130261136-1',
    {
        testMode: process.env.NODE_ENV !== 'production',
        gaOptions: {
            userId: document.getElementById('csrftoken') ? document.getElementById('csrftoken').getAttribute('content') : 'noToken'
        }
    },
);
Sentry.init({
    environment: process.env.NODE_ENV,
    debug: process.env.NODE_ENV !== 'production',
    enabled: process.env.NODE_ENV === 'production',
    dsn: "https://10d6d5d11a9743da9c019563409f4027@sentry.io/1330167",
});


const store = createStore(
    combineReducers({
        reducer: reducer,
        orderReducer: orderReducer
    }), applyMiddleware(thunk)
)

ReactDOM.render(<Provider store={store} children={<Container/>}/>, document.getElementById('root'));
const gscript = document.createElement('script');
gscript.async = true;
gscript.setAttribute('src',
    process.env.NODE_ENV === 'production' ?
        'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyAppeEw_fuUDZ6SlDOXrT6Xb7bhaGX8w7U'
        : 'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyApkG_rejbnhROtErbd2y98gA2YG4qS9AY');

document.head.appendChild(gscript);

serviceWorker.unregister();