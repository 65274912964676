import React, {Component} from "react";

export default class FancyButton extends Component {

    state = {width: null, resizing: false};

    componentDidMount() {
        window.addEventListener("resize", this.updateWidth);

        this.updateWidth()
    }


    getContainerWidth = () => {
        const element = document.getElementById(this.props.widthId)
        if (element && element.offsetWidth && !isNaN(element.offsetWidth)) {
            return element.offsetWidth;
        }
        return 400;
    }

    updateWidth = () => {
        const {resizing} = this.state
        if (!resizing) {
            this.setState({resizing: true})
            setTimeout(() => {
                this.setState({width: this.getContainerWidth() - 15, resizing: false});
            }, 250)
        }

    }


    render() {
        if (!this.state.width) {
            return null;
        }
        const {buttonText, buttonId, animateMount} = this.props;

        // Mask id and styling
        // need unique id's for multiple buttons
        const maskId = buttonId;
        const maskStyle = '#fancy-masked-element_' + maskId + ' { mask: url(#' + maskId + '); -webkit-mask: url(#' + maskId + ')}';

        const buttonStyle = {
            width: this.state.width,
            height: this.props.height
        };

        const fancyFrontStyle = {
            transform: 'rotateX(0deg) translateZ(' + this.props.height / 2 + 'px )'
        };

        const fancyBackStyle = {
            transform: 'rotateX(90deg) translateZ( ' + this.props.height / 2 + 'px )'
        };

        // SVG attributes
        const textTransform = 'matrix(1 0 0 1 ' + this.state.width / 2 + ' ' + this.props.height / 2 + ')';
        const viewBox = '0 0 ' + this.state.width + ' ' + this.props.height;

        return (
            <div className={animateMount ? "animated fadeInDown fancy-button" : "fancy-button"}
                 ref="fancybutton"
                 // id={buttonId}
                 onClick={this.props.onClick}
                 style={buttonStyle}>
                <div className="fancy-flipper">
                    <div className="fancy-front" style={fancyFrontStyle}>
                        <svg
                            height={this.props.height}
                            width={this.state.width}
                            viewBox={viewBox}>
                            <defs>
                                <mask id={maskId}>
                                    <rect width="100%" height="100%" fill="#FFFFFF"/>
                                    <text className="mask-text button-text" fill="#000000" transform={textTransform}
                                          fontSize={this.props.fontSize} width="100%" alignmentBaseline={'central'}
                                          textAnchor="middle"
                                          letterSpacing="1">{buttonText}</text>
                                </mask>
                            </defs>
                            <style>
                                {maskStyle}
                            </style>
                            <rect id={'fancy-masked-element_' + maskId} fill={this.props.color} width="100%"
                                  height="100%"/>
                        </svg>
                    </div>
                    <div className="fancy-back" style={fancyBackStyle}>
                        <svg
                            height={this.props.height}
                            width={this.state.width}
                            viewBox={viewBox}>
                            <rect stroke={this.props.color}
                                  strokeWidth={this.props.borderWidth}
                                  fill="transparent"
                                  width="100%"
                                  height="100%"/>
                            <text className="button-text" transform={textTransform} fill={this.props.color}
                                  fontSize={this.props.fontSize} alignmentBaseline={'central'} textAnchor="middle"
                                  letterSpacing="1">{buttonText}</text>
                        </svg>
                    </div>
                </div>
            </div>
        );
    }
}

FancyButton.defaultProps = {
    color: '#FFFFFF',
    height: 100,
    fontSize: '3.4vw',
    borderWidth: 18,
};

