import React from "react";
import PropTypes from "prop-types";
import Kronos from "./kronos/index";
import Moment from "moment";
import {Grid} from "semantic-ui-react";
import ReactGA from "react-ga";

export default class DateTimePicker extends React.Component {

    static propTypes = {
        value: PropTypes.any,
        icon: PropTypes.any,
        fieldId: PropTypes.string.isRequired,
        theme: PropTypes.any.isRequired,
        updateField: PropTypes.func.isRequired,
        showEasternHemisphere: PropTypes.bool.isRequired,
        placeholder: PropTypes.string,
        title: PropTypes.string,
        easternMinDate: PropTypes.any,
        minDate: PropTypes.any,
        easternMaxDate: PropTypes.any,
        maxDate: PropTypes.any,
    };

    updateField = () => {
        const {value} = this.props;
        const {updateDate} = this.state;

        if (updateDate && Moment(value).format('MM-DD-YYYY') !== Moment(updateDate).format('MM-DD-YYYY')) {
            this.props.updateField(this.props.fieldId, Moment(updateDate));
            this.setState({updateDate: null})
        }
    };

    state = {
        updateDate: null,
    };


    render() {
        const {title, value, updateField, fieldId, disableTimeLocationUpdate} = this.props;
        const {updateDate} = this.state;

        return (
            <div style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }} className={"input-section " }>
                <div className="input-section-child" style={{overflow: 'visible'}}>
                    <label className="registration-form-label" style={{paddingLeft: 0}}>{title}</label>

                    <Grid divided padded>
                        <Grid.Row centered style={{padding: '.5rem 0'}}>
                            <Grid.Column width={8} style={{paddingLeft: 0}}>
                                <Kronos
                                    disabled={disableTimeLocationUpdate}
                                    date={updateDate ? updateDate : value}
                                    inputClassName="prompt"
                                    onChangeDateTime={(date) => {
                                        updateField(fieldId, date);
                                        ReactGA.event({
                                            category: 'DATETIME-PICKER',
                                            action: 'Updated date',
                                            label: Moment(date).clone().utc().format("YYYY-MM-DD HH:mm:ss")
                                        });

                                    }}
                                    closeOnSelect={true}
                                    closeOnBlur={true}
                                    inputStyle={{
                                        textAlign: 'center',
                                        fontSize: 16,
                                        width: '100%',
                                        borderRadius: '500em'
                                    }}
                                    preventClickOnDateTimeOutsideRange={true}
                                />
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Kronos
                                    disabled={disableTimeLocationUpdate}
                                    ref="time"
                                    time={value}
                                    timeStep={15}
                                    inputClassName="prompt"
                                    preventClickOnDateTimeOutsideRange={true}
                                    onChangeDateTime={(date) => {
                                        updateField(fieldId, date);
                                        ReactGA.event({
                                            category: 'DATETIME-PICKER',
                                            action: 'Updated time',
                                            label: Moment(date).clone().utc().format("YYYY-MM-DD HH:mm:ss")
                                        });
                                    }}
                                    options={{format: {hour: 'hh:mm a'}}}
                                    inputStyle={{
                                        textAlign: 'center',
                                        width: '100%',
                                        fontSize: 16,
                                        borderRadius: '500em'
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
        )
    }

}
