import React from "react";
import ReactGA from "react-ga";
import {THEMES} from "./ThemeData";
import {Label, Segment} from "semantic-ui-react";
import ImageWithPlaceHolder from "./ImageWithPlaceHolder";

export const ThemePicker = (value, updateTheme, updateCoordinates, updateField) => (
    <div style={{
        height: '100%',
        display: 'flex',
        padding: '20px 10px 20px 10px',
        alignItems: 'center',
        justifyContent: 'center'
    }} className={"input-section"}>
        {THEMES.map(theme => (

            <div key={theme.value}
                 className={value === theme ? 'theme-img theme-current' : 'theme-img'}
                 onClick={() => {

                     if (value === null) {
                         getGeoLocation(updateField, updateCoordinates)
                     }
                     updateTheme(theme);
                     ReactGA.event({
                         category: 'THEME-PICKER',
                         action: 'Selected theme',
                         label: theme.value
                     });
                 }}>

                <Segment raised>

                    <ImageWithPlaceHolder label={<Label color={value === theme ? 'blue' : null} attached="top"
                                                        content={theme.displayName}/>} image={theme.image}
                                          style={{width: '100%'}} alt={theme.displayName}/>
                </Segment>
            </div>
        ))}

    </div>

)

function getGeoLocation(updateField, updateCoordinates) {
    try {
        navigator.geolocation.getCurrentPosition((location) => {
                updateField('locationText', 'Your GPS Location')
                updateField('locationPickerPlaceholder', 'Your GPS location (' + location.coords.latitude + ' ' + location.coords.longitude + ')')
                updateCoordinates(location.coords.latitude, location.coords.longitude)

                ReactGA.event({
                    category: 'GEOLOCATION',
                    action: 'Using geolocation to default latitude and longitude',
                    label: 'TRUE'
                });
            },
            (err) => {
                ReactGA.event({
                    category: 'GEOLOCATION',
                    action: 'NOT using geolocation to default latitude and longitude',
                    label: 'NA'
                });
            }, {timeout: 7500, maximumAge: 20000});
    } catch (err) {
        ReactGA.exception({
            description: 'Error getting geo location',
            fatal: false
        });

    }
}