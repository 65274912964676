import moment from "moment";
import {post} from "../requests/post";
import * as Sentry from "@sentry/browser";
import ReactGA from "react-ga";
import DaylightMap from "../components/generators/DaylightMap";


const getNorthSouthFromLat = (latitude) => latitude > 0 ? latitude.toFixed(4) + '° N   ' : (latitude.toFixed(4) * -1) + '° S   ';
const getEastWestFromLng = (lng) => lng > 0 ? lng.toFixed(4) + '° E' : (lng.toFixed(4) * -1) + '° W';

export const savePaymentInfo = (paymentDetails) => {
    return (dispatch, getState) => {

        dispatch({
            type: LOAD_DATA,
            property: savingOrders,
            payload: true,
        });

        const postData = Object.assign({}, getState().reducer);
        const orderReducer = Object.assign({}, getState().orderReducer);

        const sendData = {}
        sendData.paymentDetails = paymentDetails;
        sendData.contactInformation = orderReducer.contactInformation
        sendData.shippingMethod = orderReducer.shippingMethod
        sendData.size = orderReducer.size.value

        post('pay/' + postData.orders[0].customerId, sendData).then((response) => {
            dispatch({
                type: LOAD_DATA,
                property: savingOrdersError,
                payload: null,
            });
            dispatch({
                type: LOAD_DATA,
                property: savingOrders,
                payload: false,
            });

            dispatch({
                type: LOAD_DATA,
                property: customer,
                payload: response.data,
            });
        }).catch((error) => {
            Sentry.captureException(error);
            dispatch({
                type: LOAD_DATA,
                property: savingOrders,
                payload: false,
            });
            dispatch({
                type: LOAD_DATA,
                property: savingOrdersError,
                payload: error,
            });

        });
    }
}

export const createOrder = () => {
    return (dispatch, getState) => {

        if (!getState().reducer.customer) {

            dispatch({
                type: LOAD_DATA,
                property: creatingOrders,
                payload: true,
            });
            const postData = Object.assign({}, getState().orderReducer);

            postData.date = postData.dateTime.clone().utc().format("YYYYDDDDHHmm");

            let endpoint = 'createOrder';
            postData.theme = postData.theme.value;
            if (postData.theme === 'night') {
                endpoint = 'createStarOrder';
                postData.image = document.getElementById("nightStarsCanvas").toDataURL();
            } else if (postData.theme === 'moon') {
                postData.date = postData.dateTime.clone().utc().format("YYYY-MM-DD HH:mm:ss");

            } else {
                endpoint = 'createEarthOrder';
                const daylightMap = new DaylightMap(moment(postData.dateTime).clone().utc().toDate(), {})
                daylightMap.init()
                daylightMap.svgToCanvas();
                postData.date = postData.dateTime.clone().utc().format("YYYY-MM-DD HH:mm:ss");

                postData.image = daylightMap.getImageFromCanvas();
            }

            postData.latLngText = null;
            if (postData.theme !== 'earth') {
                postData.latLngText = getNorthSouthFromLat(Number(postData.latitude)) + getEastWestFromLng(Number(postData.longitude));
            }
            postData.earthDates = null

            postData.dateTimeText = postData.dateTime.format("Do of MMMM, YYYY") + ' at ' + postData.dateTime.format("h:mm a");
            post(endpoint, postData).then((response) => {
                dispatch({
                    type: LOAD_DATA,
                    property: createOrdersError,
                    payload: null,
                });
                dispatch({
                    type: LOAD_DATA,
                    property: creatingOrders,
                    payload: false,
                });

                //const existingOrders = Object.assign([], getState().reducer.orders);
                // existingOrders.push(response.data)
                dispatch({
                    type: LOAD_DATA,
                    property: orders,
                    payload: [response.data],
                });
            }).catch((error) => {
                Sentry.captureException(error);
                dispatch({
                    type: LOAD_DATA,
                    property: creatingOrders,
                    payload: false,
                });
                dispatch({
                    type: LOAD_DATA,
                    property: createOrdersError,
                    payload: error,
                });

            });
        }
    }
};

export const getEarthImage = (dateTime, locationText, showClouds, latitude, longitude) => {
    return (dispatch, getState) => {

        dispatch({
            type: LOAD_DATA,
            property: earthImageLoading,
            payload: true,
        });
        let startTime = Number(moment().format('x'));

        dispatch({
            type: RESET_EARTH,
        });

        const date = moment(dateTime).clone().utc().format("YYYY-MM-DD HH:mm:ss");

        const daylightMap = new DaylightMap(moment(dateTime).clone().utc().toDate(), {})
        daylightMap.init()
        daylightMap.svgToCanvas();

        const data = {
            showClouds: showClouds,
            latitude: latitude,
            longitude: longitude,
            locationText: getState().orderReducer.locationText,
            showAnnotation: getState().orderReducer.showAnnotation,
            date: date,
            image: daylightMap.getImageFromCanvas(),

        }

        const endpoint = 'createEarth';

        ReactGA.event({
            category: 'EARTH-PREVIEW',
            action: 'Getting earth image',
            label: endpoint
        });

        post(endpoint, data).then((response) => {
            dispatch({
                type: LOAD_DATA,
                property: earthImage,
                payload: 'data:image/png;base64,' + response.data,
            });
            dispatch({
                type: LOAD_DATA,
                property: earthImageLoading,
                payload: false,
            });
            dispatch({
                type: LOAD_DATA,
                property: earthImageDate,
                payload: date,
            });
            ReactGA.timing({
                category: 'EARTH-PREVIEW',
                variable: 'post',
                value: Number(moment().format('x')) - startTime,
            });
        }).catch((error) => {

            dispatch({
                type: LOAD_DATA,
                property: earthImageError,
                payload: {
                    title: 'Error Retrieving Image',
                    message: 'Unable to load earth image from selected date and time.',
                    status: error && error.response ? error.response.status : 500,
                    tryAgainText: 'Please select a different date and time.'
                },
            });
            dispatch({
                type: LOAD_DATA,
                property: earthImageLoading,
                payload: false,
            });
            ReactGA.exception({
                description: 'Error getting earth image:' + endpoint,
                fatal: true
            });

        });

    };
};

export const getMoonImage = (dateTime, latitude, longitude) => {

    return (dispatch) => {
        dispatch({
            type: RESET_MOON,
        });


        let startTime = Number(moment().format('x'));

        const date = moment(dateTime).clone().utc().format("YYYY-MM-DD HH:mm:ss");

        const endpoint = 'moon/' + latitude + '/' + longitude + '/' + date;

        ReactGA.event({
            category: 'MOON-PREVIEW',
            action: 'Getting moon image',
            label: endpoint
        });

        post(endpoint).then((response) => {

            ReactGA.timing({
                category: 'MOON-PREVIEW',
                variable: 'post',
                value: Number(moment().format('x')) - startTime,
            });

            dispatch({
                type: LOAD_DATA,
                property: moonImage,
                payload: 'data:image/png;base64,' + response.data,
            });
            dispatch({
                type: LOAD_DATA,
                property: moonImageLoading,
                payload: false,
            });
            dispatch({
                type: LOAD_DATA,
                property: moonImageDate,
                payload: date,
            });

        }).catch((error) => {

            dispatch({
                type: LOAD_DATA,
                property: moonImageError,
                payload: {
                    title: 'Error Retrieving Image',
                    message: 'Unable to load moon image from selected date, time, and location.',
                    status: error && error.response ? error.response.status : 500,
                    tryAgainText: 'Please select a different date and time.'
                },
            });
            dispatch({
                type: LOAD_DATA,
                property: moonImageLoading,
                payload: false,
            });
            ReactGA.exception({
                description: 'Error getting moon image:' + endpoint,
                fatal: true
            });

        });

    };
};

const LOAD_DATA = 'LOAD_DATA';
const RESET_EARTH = 'RESET_EARTH';
const RESET_MOON = 'RESET_MOON';

const createOrdersError = 'createOrdersError';
const creatingOrders = 'creatingOrders';
const savingOrders = 'savingOrders';
const savingOrdersError = 'savingOrdersError';
const orders = 'orders';
const earthDates = 'earthDates';
const billingDetails = 'billingDetails';
const paymentMethod = 'paymentMethod';
const shippingMethod = 'shippingMethod';
const contactInformation = 'contactInformation';
const customer = 'customer';
const disableTimeLocationUpdate = 'disableTimeLocationUpdate';
const moonImage = 'moonImage';
const moonImageError = 'moonImageError';
const moonImageLoading = 'moonImageLoading';
const moonImageDate = 'moonImageDate';
const earthImage = 'earthImage';
const earthImageError = 'earthImageError';
const earthImageLoading = 'earthImageLoading';
const earthImageDate = 'earthImageDate';

const locationPickerPlaceholder = 'locationPickerPlaceholder';

const initialState = {
    [earthDates]: null,
    [orders]: [],
    [savingOrdersError]: null,
    [createOrdersError]: null,
    [savingOrders]: false,
    [disableTimeLocationUpdate]: false,
    [creatingOrders]: false,
    [billingDetails]: null,
    [paymentMethod]: null,
    [contactInformation]: {},
    [shippingMethod]: null,
    [customer]: null,
    [earthImage]: null,
    [earthImageError]: null,
    [earthImageDate]: null,
    [earthImageLoading]: true,
    [moonImage]: null,
    [moonImageError]: null,
    [moonImageDate]: null,
    [moonImageLoading]: true,
    [locationPickerPlaceholder]: 'Kansas City, MO, USA',

};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case LOAD_DATA : {
            return {
                ...state,
                [action.property]: action.payload
            };
        }
        case RESET_MOON : {
            return {
                ...state,
                moonImage: null,
                moonImageError: null,
                moonImageLoading: true
            };
        }
        case RESET_EARTH : {
            return {
                ...state,
                earthImage: null,
                earthImageError: null,
                earthImageLoading: true
            };
        }
        default:
            return state;
    }

}

export default reducer