import React from "react";
import PropTypes from "prop-types";
import {Input} from "semantic-ui-react";

export default class Text extends React.Component {

  static propTypes = {
    value      : PropTypes.any,
    icon       : PropTypes.any,
    updateField: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    title      : PropTypes.string,
    fieldId    : PropTypes.string.isRequired,
  };


  render() {
    const {placeholder, title, value, updateField, fieldId} = this.props;

      return (

      <div style={{
        height        : '100%',
        display       : 'flex',
        alignItems    : 'center',
        justifyContent: 'center'
      }} className={"input-section"}>
        <div className="input-section-child">
          <label className="registration-form-label">{title}</label>
          <Input type="text" name="title"
                 className="search"
                 style={{fontSize: 16}}
                 value={value}
                 input={<input className="prompt"/> }
                 onChange={(event) => updateField(fieldId, event.target.value)}
                 fluid placeholder={placeholder}/>
        </div>
      </div>
    )
  }

}
