import React from "react";
import PreviewImage from "./supers/PreviewImage";
import {Image, Placeholder} from "semantic-ui-react";

export default class UnSelectedPreviewImage extends PreviewImage {


    componentDidMount() {

        window.addEventListener("resize", this.updateUnselectedWidth);

        setTimeout(() => {
            this.setState({unselectedImageWidth: this.getUnselectedContainerWidth()});
            this.updateWidth()
        }, 200)

    }

    getUnselectedContainerWidth = () => {
        const element = document.getElementById('unselected-image')
        if (element && element.offsetWidth && !isNaN(element.offsetWidth)) {
            return element.offsetWidth;
        }
        return 400;
    }

    updateUnselectedWidth = () => {
        const {unselectedImgResizing} = this.state
        if (!unselectedImgResizing) {
            this.setState({unselectedImgResizing: true})
            setTimeout(() => {
                if (document.getElementById('unselected-image')) {
                    this.setState({
                        unselectedImageWidth: this.getUnselectedContainerWidth(),
                        unselectedImgResizing: false
                    });
                }
            }, 100)
        }

    }

    render() {
        const {unselectedImageWidth} = this.state
        return this.renderPreviewImage(
            <Image id="unselected-image" centered circular
                   src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARgAAAEYAQMAAAC9QHvPAAAABlBMVEX+/v7///8b/GgcAAADIElEQVRo3u2Zf0gTYRjHH0LCPyRKwkTCQkSkJCJqRZiTGLZMwsTEtEaEjIpI6ZchoVREiEiEioTEiJBQkRFDRESvCBERCSvTEXpYOSl1w2SNcWzrved2d++FolFE0PPC+N697/Pe83yee+/e971Bqf+YT+yuDkkBn/wTBTx8AXyZmu9Jy3Ns2HqgYvrzUENa3qM9ZUyOakdMwJadPTPcjDaLnwYfJGfelCWmNj7V+jDFggKRpdnIKgVCWeGQFF740H15cuL5uYG6hIxiJg4lLCU6qP/mmRluStp7fvTpyY6izpKui2NMbH1V/rlxpyIgSQHWAa/J1YuG62w55JYCM3jOde2/FVyafe3IQoE7mW5HVgUi7dRclRhtvqbnh6Tg22fHHx+Mosoih5dTn6QI+OVIwuicw9USLguIAqsYx9xx90KNCwWcIYnlZqTFdMkdjUWWUnbqsmOtyw6jcwFRqEEHk703MJdMpgz5se2q7rmiIHh1o++qsSxQOTnhdpVjHJyv4t1nX95dH5eIAjVRR8Zi5Opd9lZLvBGIUkDttuJ1/ib7VFlbQoYNbx835o+oA1sWiPQFhepVxvNa2IVev9oQMrRwY7Wxio1nD1ao446JMWYBU/bzmDeym31nVC/XtVqndeP27NvrYmJRYD4vKApX0bUnmlcmdv1pLxsi9v+U3WbNn351H9/zqjKJ40wagMUhLvta4PITNms2HMub1tymHYVtBSjQPLggBbzYzCXmXbQZ5Y+xuwYuqPMF93L/aOEmMEi2WNVnn5vjDFMlsRM7sRM7sf8Ce4OJgXfhYo6LuVhf/tclyOv5VefK7jWs5+NTO1Isyrylbx1K2nDxfu0LCoh6nrkS5h3CJpMzp34zJnSfvhY9xU2DaSAIkWVifq95ZULsxE7sxE7sxE7sxE7sxE7s/yi7KH+QVsJYcb9TULs43Kxso8p1GqPN/llnUedp3P3wvrSP2Exgm8fbmtuYnv/k8D2znpM5/s8ASIw90WKy45aqvVDbKfYbuCrxIzr24qrbx7hkETuxEzuxEzuxEzuxEzuxEzuxE/vvs/8AiGR4ew5xynkAAAAASUVORK5CYII="
                   style={{
                       border: '3px solid #fff',
                       height: '95%',
                       width: '95%',
                   }}>
                <Placeholder fluid style={{height: unselectedImageWidth}}>
                    <Placeholder.Image/>
                </Placeholder>
            </Image>
        );

    }

}

