import React, {Component} from "react";
import {Placeholder} from "semantic-ui-react";
import PropTypes from "prop-types";

export default class ImageWithPlaceHolder extends Component {

    static propTypes = {
        image: PropTypes.any.isRequired,
        style: PropTypes.object.isRequired,
        alt: PropTypes.string.isRequired,
        label: PropTypes.any,
    };

    state = {loaded: false}

    render() {
        const {loaded} = this.state
        const {image, alt, style, label} = this.props

        return (
            <>
            {(loaded && label) && label}
            <img
                alt={alt}
                style={loaded ? style : {display: 'none'}}
                src={image}
                onLoad={() => this.setState({loaded: true})}
            />
            {!loaded &&
            <Placeholder fluid>
                <Placeholder>
                    <Placeholder.Image />
                </Placeholder>
                <Placeholder.Line />
                <Placeholder.Line />
            </Placeholder>
            }

            </>
        )

    }
}