import React from "react";
import {
    Header,
    Icon,
    Modal,
    Segment
} from "semantic-ui-react";

export const AboutUs = close => <Modal open={true} onClose={close} closeIcon={true}>

        <Header as='h2' attached='top'>
            <Icon name='info circle' color="teal"/>
            <Header.Content>
                About Us
                <Header.Subheader>
                    A team of creative dreamers.
                </Header.Subheader>
            </Header.Content>
        </Header>
    <Modal.Content>
        <Segment  raised>
            Our founder, Jason, initially developed the idea after his Grandma passed away. Many of Jason's favorite
            memories were of him sitting on the front porch with his Grandma while gazing at the moon and stars. The
            night his Grandma passed, Jason
            distinctly remembers how bright and beautiful the moon looked. He wanted to capture that moment and treasure
            it forever. While developing the
            moon image render, he thought of other various methods to remember loved ones or special moments in one's
            life.
            <br/>
            Nothing brings us more joy than seeing our customer's face light up they receive their treasure moment.
            We take extreme pride in our customer satisfaction and handle each order with great care.
        </Segment>
    </Modal.Content>
</Modal>