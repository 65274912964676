import React from "react";
import {post} from "../requests/post";
import {Form} from "formsy-semantic-ui-react";
import {
    Button,
    Header,
    Icon,
    Message,
    Modal,
    Segment
} from "semantic-ui-react";
import {ErrorLabel} from "./checkout/ErrorLabel";

export default class ContactForm extends React.Component {

    state = {
        loading: false,
        success: false,
        error: null
    }
    onContactFormSubmit = (formData) => {
        this.setState({loading: true, error: false, success: false})
        post('contactForm', formData).then((response) => {
            this.setState({success: true, loading: false})

        }).catch(erro => {
            this.setState({error: true, loading: false})

        })
    }

    render() {
        const {success, loading, error} = this.state;
        const {close} = this.props;
        return (

            <Modal open onClose={close} closeIcon>

                <Header as='h2' attached='top'>
                    <Icon name='mail' color="blue" circular/>
                    <Header.Content>
                        E-mail Our Team
                        <Header.Subheader>
                            Our team is available to assist you with any questions.
                        </Header.Subheader>
                    </Header.Content>

                </Header>

                <Modal.Content>
                <Segment  raised>
                    <Form
                        onValidSubmit={this.onContactFormSubmit }
                        loading={loading}
                        success={success}
                        error={error}
                    >
                        <Message success header="Thank You"
                                 content="A team member will be reaching out to you shortly!"/>
                        <Message error header="Error Sending Message"
                                 content="Please try again or contact support!"/>
                        <Form.Group widths='equal'>
                            <Form.Input
                                name="first_name"
                                label="First Name"
                                required
                                validationErrors={{
                                    isDefaultRequiredValue: 'First Name is Required',
                                }}
                                errorLabel={ ErrorLabel }
                            />
                            <Form.Input
                                name="last_name"
                                label="Last Name"
                                required
                                validationErrors={{
                                    isDefaultRequiredValue: 'Last Name is Required',
                                }}
                                errorLabel={ ErrorLabel }
                            />
                        </Form.Group>
                        <Form.Input
                            name="email"
                            label="Email"
                            validations="isEmail"
                            required
                            validationErrors={{
                                isEmail               : 'Email is invalid',
                                isDefaultRequiredValue: 'Email is Required',
                            }}
                            errorLabel={ErrorLabel}
                        />
                        <Form.Input
                            name="phone"
                            label="Phone"
                        />
                        <Form.TextArea required
                                       validationErrors={{isDefaultRequiredValue: 'A message is required',}}
                                       label='Message'
                                       name="notes"/>


                        <button type="submit" style={{display:'none'}} id="contactSubmitButton"/>
                    </Form>
                </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary type="button" onClick={()=>document.getElementById('contactSubmitButton').click()} disabled={loading || success} loading={loading}
                            animated='vertical'>
                        <Button.Content visible>
                            <Icon name='send' size="large"/>
                        </Button.Content>
                        <Button.Content hidden><p>Send</p></Button.Content>
                    </Button>
                    <Button type="button" floated="right" onClick={close} disabled={loading} loading={loading}
                            animated='fade'>
                        <Button.Content visible>
                            <Icon name='close' size="large"/>
                        </Button.Content>
                        <Button.Content hidden><p>Close</p></Button.Content>
                    </Button>
                </Modal.Actions>
            </Modal>

        )
    }

}
