/* eslint-disable no-cond-assign */

import animateScrollTo from "animated-scroll-to";

export const scrollTo = elementId => {
    let element = document.getElementById(elementId);

    if (element) {
        animateScrollTo(findPos(element) - 50, scrollOptions)
    }
};
const scrollOptions = {
    speed             : 1000,
    minDuration       : 250,
    maxDuration       : 1500,
    element           : window,
    cancelOnUserAction: true
};

export function findPos(obj) {
    let curtop = 0;
    if (obj.offsetParent) {
        do {
            curtop += obj.offsetTop;
        } while (obj = obj.offsetParent);
        return [curtop];
    }
}
