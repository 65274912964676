import {connect} from "react-redux";
import {createOrder, getEarthImage, getMoonImage, savePaymentInfo} from "../modules/reducer";
import {
    selectColorTheme,
    updateCoordinates,
    updateField,
    updateIncludeFrame,
    updateTotalAmount,
} from "../modules/orderReducer";
import Home from "../components/Home";

const mapDispatchToProps = {
  updateField         : (fieldId, value) => updateField(fieldId, value),
    createOrder       : () => createOrder(),
    updateTotalAmount : (value) => updateTotalAmount(value),
    updateIncludeFrame: (includeFrame) => updateIncludeFrame(includeFrame),
    savePaymentInfo   : (paymentDetails) => savePaymentInfo(paymentDetails),
    updateCoordinates: (lat, lng) => updateCoordinates(lat, lng),
    getMoonImage      : (dateTime, latitude, longitude) => getMoonImage(dateTime, latitude, longitude),
    selectColorTheme: (config) => selectColorTheme(config),
    getEarthImage: (dateTime, label, showClouds, latitude, longitude) => getEarthImage(dateTime, label, showClouds, latitude, longitude),

};

const mapStateToProps = (state) => {

    return {...state.reducer, ...state.orderReducer};
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Home);
export default Container;
