import React from "react";
import PreviewImage from "./supers/PreviewImage";
import Error from "./Error";
import PropTypes from "prop-types";
import Loading from "./Loading";
import moment from "moment";
import {Image} from "semantic-ui-react"

export default class EarthPreviewImage extends PreviewImage {
    static propTypes = {
        showEasternHemisphere: PropTypes.bool.isRequired,
        showMapLines: PropTypes.bool.isRequired,
        earthDates: PropTypes.any,
    };


    componentDidMount() {
        if ((!this.props.earthImage || this.props.earthImageDate !== moment(this.props.dateTime).clone().utc().format('YYYY-MM-DD HH:mm:ss'))) {

            this.props.getEarthImage(this.props.dateTime, this.props.locationText, this.props.showClouds, this.props.latitude, this.props.longitude)
        }
    }

    componentDidUpdate(prevProps) {
        const {dateTime, showClouds, locationText, latitude, longitude, showAnnotation} = this.props;
        if ((prevProps.dateTime !== dateTime || prevProps.latitude !== latitude || prevProps.longitude !== longitude
            || prevProps.showClouds !== showClouds
            || prevProps.showAnnotation !== showAnnotation
        )) {
            this.props.getEarthImage(dateTime, locationText, showClouds, latitude, longitude)
        }
        return true
    }

    render() {
        const {earthImage, earthImageError, earthImageLoading, imageBorderColor, latitude, longitude, showImageBorder, dateTime} = this.props;

        if (earthImageError) {
            return this.renderPreviewImage(<>
                <Error {...earthImageError}/>
                {CanvasImage()}
            </>);
        }

        if (earthImageLoading) {
            return this.renderPreviewImage(<>
                    <Loading
                        message={"Generating image of the earth for latitude (" + Number(latitude).toFixed(4) + ") and longitude (" + Number(longitude).toFixed(4) + ") on the "
                        + moment(dateTime).clone().format('Do of MMMM YYYY @ h:mm a')}
                        title="Loading Image"/>
                    {CanvasImage()}
                </>
            )
        }
        return this.renderPreviewImage(
            <>
                <Image centered circular src={earthImage} style={{
                    border: '3px solid ' + (showImageBorder ? imageBorderColor : 'rgba(0,0,0,0)'),
                    height: '95%',
                    width: '95%',
                    borderRadius: '50%',
                    pointerEvents: 'none'
                }}/>
                {CanvasImage()}
            </>
        )

    }
}
const CanvasImage = () => (
    <div style={{display: 'none'}}>
        <svg id="earth-day-night"/>

        <canvas id={"earth-day-night-canvas"} width={2048} height={2048 / 2}/>
    </div>
)
