import React, {Suspense} from "react";
import {Grid, Header, Icon, Responsive, Step} from "semantic-ui-react";
import ReactGA from "react-ga";
import {scrollTo} from "../../utils/Scroll";

const ContactInfoForm = React.lazy(() => import("./ContactInfoForm"));
const ShippingMethodForm = React.lazy(() => import("./ShippingMethodForm"));
const PaymentMethodsForm = React.lazy(() => import("./PaymentMethodsForm"));
const ConfirmOrderForm = React.lazy(() => import("./ConfirmOrderForm"));
const CartPreview = React.lazy(() => import("./CartPreview"));

export default class PaymentInfo extends React.Component {

    state = {
        activeStepIndex: 0,
    };

    componentDidMount() {
        setTimeout(() => scrollTo('payment-info'), 250)
    }


    componentDidUpdate() {
        if (this.props.customer !== null && this.state.activeStepIndex < 3) {
            this.setState({
                activeStepIndex: 3
            });
        }
    }

    render() {
        const {customer, cancelCheckout,} = this.props;
        const {activeStepIndex,} = this.state;
        return (
            <div id="payment-info">
                <Responsive minWidth={768}>
                    <div style={{
                        textAlign: 'center',
                        paddingTop: 20
                    }}>
                        {this.stepGroup(cancelCheckout, activeStepIndex)}

                    </div>
                    <Grid padded>
                        <Grid.Row>
                            <Grid.Column width={1}/>
                            <Grid.Column width={6}>
                                <Suspense maxDuration={3000} fallback={Fallback('Loading...')}>

                                    {this.renderFormSection(activeStepIndex)}

                                    {(customer !== null && activeStepIndex === 3) && <ConfirmOrderForm
                                        onValidSubmit={() => {

                                        }}
                                        customer={customer}
                                    />}
                                </Suspense>
                            </Grid.Column>
                            <Grid.Column width={2}/>
                            <Grid.Column width={6}>

                                <Suspense maxDuration={3000} fallback={Fallback('Loading Cart Preview')}>
                                    <CartPreview {...this.props}/>
                                </Suspense>
                            </Grid.Column>
                            <Grid.Column width={1}/>
                        </Grid.Row>
                    </Grid>
                </Responsive>

                <Responsive maxWidth={768}>

                    <Grid padded columns={1}>
                        <Grid.Row>
                            <Grid.Column >
                                <Suspense maxDuration={3000} fallback={Fallback('Loading Cart Preview')}>
                                    <CartPreview {...this.props}/>
                                </Suspense>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                {this.stepGroup(cancelCheckout, activeStepIndex, true)}

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column >
                                <Suspense maxDuration={3000} fallback={Fallback('Loading...')}>
                                    {this.renderFormSection(activeStepIndex)}
                                    {(customer !== null && activeStepIndex === 3) && <ConfirmOrderForm
                                        onValidSubmit={() => {

                                        }}
                                        customer={customer}
                                    />}
                                </Suspense>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Responsive>
            </div>
        )
    }

    stepGroup = (cancelCheckout, activeStepIndex, unstackable) => (
        <Step.Group unstackable={unstackable} style={unstackable ? {
            width: '100%',
            display: 'inline-block',
            textAlign: 'left',
        } : {
            width: '95%',
            display: 'inline-block',
            textAlign: 'left',
            maxHeight: 60

        }}>
            <Step className={'checkout-step'}
                  style={{width: '19%'}}
                  content={
                      <Header as='h4' icon={unstackable}>
                          {unstackable ?
                              <Icon inverted style={{fontSize: 8}} circular name={'checkmark'} color='green'/>

                              : <Icon size={'large'} name={'checkmark'} color='green'/>}
                          <Header.Content >
                              Design Theme
                              <Header.Subheader >Personalize your moment</Header.Subheader>
                          </Header.Content>
                      </Header>
                  }
                  completed={!unstackable}
                  onClick={cancelCheckout}
            />

            {steps.map((step, index) => {
                return unstackable ? this.stepHeaderMobile(step, index, activeStepIndex) : this.stepHeader(step, index, activeStepIndex)

            })}
        </Step.Group>
    )

    stepHeader = ({key, title, width, description, iconName, color,}, index, activeStepIndex) => (
        <Step className={'checkout-step'}
              style={{width: width}}
              key={key}
              active={activeStepIndex === index}
              content={
                  <Header as='h4'>
                      {getStepIcon(iconName, color, index, activeStepIndex)}
                      <Header.Content >
                          {title}
                          <Header.Subheader >{description}</Header.Subheader>
                      </Header.Content>
                  </Header>
              }
              disabled={activeStepIndex > 2 || activeStepIndex < index}
              completed={activeStepIndex > index}
              onClick={() => {
                  if (activeStepIndex > index) {
                      this.setState({activeStepIndex: index})
                  }
              }}
        />

    )

    stepHeaderMobile = ({key, title, width, description, iconName, color,}, index, activeStepIndex) => (
        <Step className={'checkout-step'}
              style={{width: width}}
              key={key}
              active={activeStepIndex === index}
              content={
                  <Header as='h4' icon>
                      {getStepIconMobile(iconName, color, index, activeStepIndex, 8)}
                      <Header.Content >
                          {title}
                          <Header.Subheader >{description}</Header.Subheader>
                      </Header.Content>
                  </Header>
              }
              disabled={activeStepIndex > 2 || activeStepIndex < index}
              onClick={() => {
                  if (activeStepIndex > index) {
                      this.setState({activeStepIndex: index})
                  }
              }}
        />

    )

    renderFormSection = activeIndex => {
        const {contactInformation, shippingMethod, customer, paymentMethod, orders, updateField} = this.props
        if (activeIndex === 1) {
            return <ContactInfoForm contactInformation={contactInformation}
                                    orders={orders}
                                    onValidSubmit={(formData) => {
                                           ReactGA.event({
                                               category: 'CHECKOUT',
                                               action: 'Submitted Contact Info'
                                           });
                                           updateField("contactInformation", formData)

                                           this.setState({
                                               activeStepIndex: 2
                                           });

                                       }}
            />
        } else if (activeIndex === 0) {
            return <ShippingMethodForm shippingMethod={shippingMethod}
                                       onValidSubmit={(formData) => {

                                           ReactGA.event({
                                               category: 'CHECKOUT',
                                               action: 'Submitted Shipping Method',
                                               label: formData
                                           });
                                           updateField("shippingMethod", formData);
                                           this.setState({
                                               activeStepIndex: 1
                                           });
                                       }}
            />
        } else if (activeIndex === 2 || customer !== null) {
            return <PaymentMethodsForm {...paymentMethod}
                                       onValidSubmit={(formData) => {
                                       }}
                                       {...this.props}
            />
        }
        return null;
    }
}

const steps = [
    {
        key: 'shipping',
        color: 'orange',
        iconName: 'shipping fast',
        title: 'Shipping',
        width: '19%',
        description: 'Choose a shipping method',
    },
    {
        key: 'info',
        color: 'olive',
        iconName: 'mail',
        width: '21%',
        title: 'Contact Information',
        description: 'Provide an address',
    },

    {
        key: 'payment',
        color: 'teal',
        width: '21%',
        iconName: 'credit card',
        title: 'Payment Method',
        description: 'Quick and painless checkout',
    },

    {
        key: 'confirm',
        iconName: 'clipboard check',
        color: 'green',
        width: '20%',
        title: 'Order Confirmation',
        description: 'Review your purchase',

    },
]

function getStepIcon(icon, color, index, activeStepIndex, size) {

    if (activeStepIndex < index) {
        return <Icon style={size ? {fontSize: size} : null} name={icon} color='grey'/>
    } else if (activeStepIndex > index) {
        return <Icon style={size ? {fontSize: size} : null} name={'checkmark'} color='green'/>
    }

    return <Icon style={size ? {fontSize: size} : null} name={icon} color={color}/>
}

function getStepIconMobile(icon, color, index, activeStepIndex, size) {

    if (activeStepIndex < index) {
        return <Icon style={size ? {fontSize: size} : null} circular name={icon} color='grey'/>
    } else if (activeStepIndex > index) {
        return <Icon style={size ? {fontSize: size} : null} inverted circular name={'checkmark'} color='green'/>
    }

    return <Icon style={size ? {fontSize: size} : null} circular name={icon} color={color}/>
}

const Fallback = title => (
    <div style={{display: 'none'}}/>
    /*<Segment raised style={{minHeight:100}}>
        <Dimmer  active={true}>
            <Loader active={true} content={title}/>
        </Dimmer>
    </Segment>*/
)