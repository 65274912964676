import DateTimePicker from "./DateTimePicker";
import TextArea from "./TextArea";
import Text from "./Text";
import Select from "./Select";
import OptionsInput from "./supers/OptionsInput";
import LocationPicker from "./LocationPicker";
import MoonImage from "./images/moonRound.jpg";
import NightStarsImage from "./images/starsRound.jpg";
import EarthImage from "./images/earthRound.jpg";
import EarthLocationText from "./EarthLocationText";

const SHOW_FRAME_BORDER_PROPS = {
    fieldId: 'showFrameBorder',
    title: 'Frame Border',
};
const SHOW_IMAGE_BORDER_PROPS = {
    fieldId: 'showImageBorder',
    title: 'Image Border',
};

const LOCATION_GEO_PROPS = {
    title: 'Where do you want to see the stars from?',
    fieldId: 'location'
};
const PERSONAL_MESSAGE_PROPS = {
    placeholder: 'Add a special message or quote',
    title: 'Personal Message',
    fieldId: 'personalMessage'
};
const TITLE_TEXT_PROPS = {
    placeholder: 'Add a title',
    title: 'Title',
    fieldId: 'title'
};
const DATE_TIME_PROPS = {
    title: 'When was your special moment?',
    fieldId: 'dateTime',
};
const LOCATION_TEXTS_PROPS = {
    title: 'Location Text',
    placeholder: 'Write why this location is such a special place.',
    fieldId: 'locationText'
};

const EARTH = {
    displayName: 'Earth',
    value: 'earth',
    image: EarthImage,
    inputs: [
        {
            Component: LocationPicker,
            props: {
                ...LOCATION_GEO_PROPS,
                title: 'Where do you want to see the earth centered?'
            }
        },
        {
            Component: DateTimePicker,
            props: {
                ...DATE_TIME_PROPS,
            }
        },
        {
            Component: TextArea,
            props: PERSONAL_MESSAGE_PROPS
        },
        {
            Component: Text,
            props: TITLE_TEXT_PROPS
        },

        {
            Component: EarthLocationText,
            props: LOCATION_TEXTS_PROPS
        },
        {
            Component: Select,
            props: {fieldId: 'size'}
        }
    ],
    options: [

        {
            Component: OptionsInput,
            props: {
                fieldId: 'showClouds',
                title: 'Show Clouds',
            }
        },
        {
            Component: OptionsInput,
            props: {
                fieldId: 'showAnnotation',
                title: 'Show Location Label',
            }
        },
        {
            Component: OptionsInput,
            props: SHOW_IMAGE_BORDER_PROPS
        },
        {
            Component: OptionsInput,
            props: SHOW_FRAME_BORDER_PROPS
        }
    ]
};
const NIGHT = {
    displayName: 'Stars',
    value: 'night',
    image: NightStarsImage,
    inputs: [

        {
            Component: LocationPicker,
            props: LOCATION_GEO_PROPS
        },
        {
            Component: DateTimePicker,
            props: DATE_TIME_PROPS
        },
        {
            Component: TextArea,
            props: PERSONAL_MESSAGE_PROPS
        },
        {
            Component: Text,
            props: TITLE_TEXT_PROPS
        },

        {
            Component: Text,
            props: LOCATION_TEXTS_PROPS
        },
        {
            Component: Select,
            props: {fieldId: 'size'}
        }
    ],
    options: [
        {
            Component: OptionsInput,
            props: {
                fieldId: 'showingConstellationLines',
                title: 'Constellation Lines',
            }
        },
        {
            Component: OptionsInput,
            props: {
                fieldId: 'showingConstellationLabels',
                title: 'Constellation Labels',
            }
        },
        {
            Component: OptionsInput,
            props: {
                fieldId: 'showingStarNames',
                title: 'Star & Planet Names',
            }
        },
        {
            Component: OptionsInput,
            props: SHOW_IMAGE_BORDER_PROPS
        },
        {
            Component: OptionsInput,
            props: SHOW_FRAME_BORDER_PROPS
        },
    ],
};

const MOON = {
    displayName: 'Moon',
    value: 'moon',
    image: MoonImage,
    inputs: [
        {
            Component: LocationPicker,
            props: {
                ...LOCATION_GEO_PROPS,
                title: 'Where do you want to see the moon from?'
            }
        },
        {
            Component: DateTimePicker,
            props: DATE_TIME_PROPS
        },
        {
            Component: TextArea,
            props: PERSONAL_MESSAGE_PROPS
        },
        {
            Component: Text,
            props: TITLE_TEXT_PROPS
        },

        {
            Component: Text,
            props: LOCATION_TEXTS_PROPS
        },
        {
            Component: Select,
            props: {fieldId: 'size'}
        }

    ],
    options: [
        /*{
         Component: OptionsInput,
         props    : {
         fieldId: 'transparentUnlitMoon',
         title  : 'Transparent Shadow',
         icon   : 'question circle outline',
         tooltip: 'When enabled, the black portion of the moon is made to be transparent.'
         }
         },*/
        {
            Component: OptionsInput,
            props: SHOW_IMAGE_BORDER_PROPS
        },
        {
            Component: OptionsInput,
            props: SHOW_FRAME_BORDER_PROPS
        },
    ]
};

export const THEMES = [
    EARTH,
    MOON,
    NIGHT,
];
