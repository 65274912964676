import React, {Suspense} from "react";
import {Button, Grid, Icon, Menu, Responsive, Segment, Sidebar, Sticky} from "semantic-ui-react";
import {ThemePicker} from "./ThemePicker";
import NightStarsPreviewImage from "./NightStarsPreviewImage";
import MoonPreviewImage from "./MoonPreviewImage";
import EarthPreviewImage from "./EarthPreviewImage";
import FancyButton from "./FancyButton";
import Payment from "./checkout/PaymentInfo";
import SequenceBanner from "./SequenceBanner";
import UnSelectedPreviewImage from "./UnSelectedPreviewImage";
import Footer from "./Footer";
import ReactGA from "react-ga";
import {scrollTo} from "../utils/Scroll";

const ThemeOptions = React.lazy(() => import("./supers/ThemeOptions"));
const ColorPickerInputs = React.lazy(() => import("./supers/ColorPickerInputs"));
const ThemeInfoOptions = React.lazy(() => import("./ThemeInfoOptions"));
const ColorThemePicker = React.lazy(() => import("./ColorThemePicker"));

export default class Home extends React.Component {

    state = {
        contextRef: null,
        checkoutFormShowing: false,
        showMobileStickyMenu: false,
        previewModalVisible: false,
        scrollDistance: 0,
        savingDesign: false,

    };

    handleContextRef = contextRef => this.setState({contextRef});

    saveDesign = () => {
        this.props.createOrder();
        this.setState({
            checkoutFormShowing: true,
            savingDesign: false,
            showMobileStickyMenu: false
        });
        if (!this.state.checkoutFormShowing) {
            ReactGA.modalview('checkout')
            ReactGA.event({
                category: 'CHECKOUT',
                action: 'Saving design',
                label: this.props.theme.value,
            });
        } else {
            ReactGA.event({
                category: 'CHECKOUT',
                action: 'Updating design',
                label: this.props.theme.value,
            });
        }

    };

    componentDidMount() {
        ReactGA.pageview('/');
        ReactGA.event({
            category: 'PAGE_LOAD',
            action: 'Loaded Initial Page',
            label: document.getElementById('csrftoken') ? document.getElementById('csrftoken').getAttribute('content') : '',
        });

    }

    render() {
        const {theme, updateField, dateTime} = this.props;
        const {contextRef, savingDesign, scrollDistance, checkoutFormShowing, previewModalVisible, showMobileStickyMenu} = this.state;

        return (

            <div style={{backgroundColor: '#d2d6dc'}}>
                <Responsive maxWidth={768}>
                    {(showMobileStickyMenu && theme) && <Menu fixed="bottom" fluid widths={1} style={{
                        zIndex: 9999,
                        boxShadow: '0 5px 2px 0 rgba(34,36,38,.15)'
                    }}>
                        <Menu.Item >
                            <Button.Group>

                                <Button id={'checkout-button'} positive disabled={!previewModalVisible} onClick={() => {
                                    this.setState({
                                        previewModalVisible: false,
                                        savingDesign: true
                                    });
                                }}><Icon name="shopping cart"/>Save & Checkout</Button>
                                <Button.Or text='or'/>

                                <Button color="teal"
                                        onClick={() => {
                                            if (previewModalVisible) {
                                                this.setState({
                                                    previewModalVisible: false,
                                                })
                                            } else {
                                                this.setState({
                                                    previewModalVisible: true,
                                                    scrollDistance: getScrollDistance()
                                                })
                                            }

                                        }}>
                                    <Icon
                                        name={previewModalVisible ? 'close' : 'file image outline'}/>{previewModalVisible ? 'Close Preview' : 'Preview Design'}
                                </Button>

                            </Button.Group>
                        </Menu.Item>

                    </Menu>}
                    <Sidebar.Pushable>
                        <Sidebar
                            style={{
                                width: '100%',
                                zIndex: 999999
                            }}
                            onHidden={() => {
                                if (savingDesign) {
                                    this.saveDesign();
                                }
                            }}
                            animation='overlay'
                            direction={'right'}
                            visible={previewModalVisible}
                            width='wide'>
                            <Segment raised style={{marginTop: scrollDistance}} textAlign={'center'}>

                                {this.renderThemePreview(theme)}

                            </Segment>

                        </Sidebar>
                        <Sidebar.Pusher dimmed={previewModalVisible}>

                            <SequenceBanner/>

                            <div id="themePicker-small" className={theme ? "form-back theme-selected" : "form-back"}>
                                <div style={{padding: 10}}>
                                    {this.renderForm(theme, dateTime, updateField)}
                                    {theme && <div style={{paddingTop: 25}} className="registration-form"
                                                   id="fancy-button-container-small">
                                        <FancyButton widthId={'fancy-button-container-small'}
                                                     buttonText="PREVIEW DESIGN"
                                                     buttonId="saveDesign"
                                                     fontSize="9vw"
                                                     onClick={() => {
                                                         this.setState({
                                                             previewModalVisible: true,
                                                             scrollDistance: getScrollDistance()
                                                         })
                                                     }}/>

                                    </div>}
                                </div>
                            </div>

                            {checkoutFormShowing &&
                            <Payment {...this.props} cancelCheckout={() => {
                                scrollTo('themePicker-small');
                                this.setState({checkoutFormShowing: false})
                            }}/>
                            }
                            <Footer/>
                        </Sidebar.Pusher>
                    </Sidebar.Pushable>

                </Responsive>


                <Responsive minWidth={768}>
                    <SequenceBanner/>
                    <div id="themePicker" className={theme ? "form-back theme-selected" : "form-back"}>
                        <Grid padded stackable>
                            <Grid.Row centered>
                                <Grid.Column width={1}/>
                                <Grid.Column width={6} style={{textAlign: 'initial'}}>
                                    <div ref={ this.handleContextRef}>

                                        {this.renderForm(theme, dateTime, updateField)}

                                        {theme && <div style={{paddingTop: 25}} id="fancy-button-container"
                                                       className="registration-form">
                                            <FancyButton widthId={'fancy-button-container'}
                                                         buttonText={checkoutFormShowing ? 'UPDATE DESIGN' : "SAVE & CHECKOUT"}
                                                         buttonId="saveDesign" onClick={() => {

                                                this.saveDesign()

                                            }}/>

                                        </div>}
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={2}/>
                                <Grid.Column width={6}>
                                    <Sticky active={theme !== null} context={contextRef}>
                                        <div style={{width: '100%'}} ref="themePreview">
                                            {this.renderThemePreview(theme)}
                                        </div>
                                    </Sticky>
                                </Grid.Column>
                                <Grid.Column width={1}/>
                            </Grid.Row>
                        </Grid>
                    </div>

                    {checkoutFormShowing && <Payment {...this.props} cancelCheckout={() => {
                        scrollTo('themePicker');
                        this.setState({checkoutFormShowing: false})
                    }}/>}

                    <Footer/>
                </Responsive>

            </div>
        )
    }

    renderThemePreview = theme => {

        if (!theme) {
            return (
                <UnSelectedPreviewImage {...this.props}/>
            )
        } else if (theme.value === 'night') {
            return (
                <NightStarsPreviewImage
                    {...this.props}
                />
            )
        } else if (theme.value === 'earth') {
            return (
                <EarthPreviewImage
                    {...this.props}
                    showLatLngText={false}
                />
            )
        } else if (theme.value === 'moon') {
            return (
                <MoonPreviewImage
                    {...this.props}
                />
            )
        }
    }

    updateTheme = (theme) => {
        this.props.updateField('theme', theme)
        this.setState({
            showMobileStickyMenu: true
        })
    }

    renderForm = (theme, dateTime, updateField) => (

        <div>
            <div className="registration-form">
                <header>
                    <h1>Choose A Theme</h1>
                    <p>Select one of our beautifully crafted designs to personalize.</p>
                </header>

                {ThemePicker(theme, this.updateTheme, this.props.updateCoordinates, this.props.updateField) }

                <Suspense maxDuration={5555} fallback={Fallback('Loading Poster Options')}>
                    {theme && <ThemeInfoOptions theme={theme} {...this.props} />}
                </Suspense>

            </div>

            <Suspense maxDuration={3000} fallback={Fallback('Loading Color Themes')}>
                {theme && <ColorThemePicker updateColorTheme={this.props.selectColorTheme}
                                            currentColorTheme={this.props.selectedColorTheme}/>}
            </Suspense>

            <Suspense maxDuration={3000} fallback={Fallback('Loading Theme Options')}>
                {theme && <ThemeOptions {...this.props} title="Theme Options" options={theme ? theme.options : []}
                                        updateField={updateField}/>}
            </Suspense>

            <Suspense maxDuration={3000} fallback={Fallback('Loading Color Options')}>
                {theme && <ColorPickerInputs theme={theme} {...this.props} />}
            </Suspense>

        </div>
    )
}

function getScrollDistance() {
    return (window.pageYOffset || document.documentElement.scrollTop) + 60
}

const Fallback = title => (
    <div style={{display: 'none'}}/>
    /*<Segment raised style={{minHeight:100}}>
        <Dimmer  active={true}>
            <Loader active={true} content={title}/>
        </Dimmer>
    </Segment>*/
)