import React from "react";
import PropTypes from "prop-types";
import {Input} from "semantic-ui-react";

export default class EarthLocationText extends React.Component {

    static propTypes = {
        value: PropTypes.any,
        icon: PropTypes.any,
        updateField: PropTypes.func.isRequired,
        placeholder: PropTypes.string,
        title: PropTypes.string,
        fieldId: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {value: props.locationText}
    }


    render() {
        const {placeholder, title, showAnnotation, locationText, updateField, fieldId, getEarthImage, dateTime, showClouds, latitude, longitude} = this.props;
        const {value} = this.state
        return (

            <div style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }} className={"input-section"}>
                <div className="input-section-child">
                    <label className="registration-form-label">{title}</label>
                    <Input type="text" name="title"
                           className="search"
                           style={{fontSize: 16}}
                           value={locationText}
                           onBlur={() => {
                               if (showAnnotation && value !== locationText && locationText !== 'Your GPS Location') {
                                   getEarthImage(dateTime, locationText, showClouds, latitude, longitude)
                                   this.setState({value: locationText})
                               }
                           }}
                           input={<input className="prompt"/>}
                           onChange={(event) => {
                               updateField(fieldId, event.target.value)
                           }}
                           fluid placeholder={placeholder}/>
                </div>
            </div>
        )
    }

}
