import React from "react";
import {Button, Header, Icon, Image, Modal} from "semantic-ui-react";
// @ts-ignore
import Earth from "./images/earthPoster.jpg";

type Props = {
    close: () => void;
    type: string;
}
export default class Gallery extends React.Component<Props> {

    render() {
        const {close, type} = this.props;
        return (
            <Modal open centered onClose={close} closeIcon>
                <Modal.Header>
                    <Header as='h2'>
                        <Icon name='images'/>
                        <Header.Content>
                            {type}
                            <Header.Subheader>
                                Our team is available to assist you with any questions.
                            </Header.Subheader>
                        </Header.Content>

                    </Header>
                </Modal.Header>
                <Modal.Content image>
                    {type === 'earth' && <Image wrapped size='medium' src={Earth}/>}
                </Modal.Content>
                <Modal.Actions>
                    <Button type="button" onClick={close}
                            animated='fade'>
                        <Button.Content visible>
                            <Icon name='close' size="large"/>
                        </Button.Content>
                        <Button.Content hidden><p>Close</p></Button.Content>
                    </Button>

                </Modal.Actions>
            </Modal>

        )
    }

}
