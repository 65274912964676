/* eslint-disable no-undef */
import React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";

export default class LocationPicker extends React.Component {

    static propTypes = {
        value      : PropTypes.any,
        icon       : PropTypes.any,
        updateField: PropTypes.func.isRequired,
        locationPickerPlaceholder: PropTypes.string,
        title      : PropTypes.string,
        fieldId    : PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.GoogleAutoComplete = null;
        this.GoogleSessionToken = null;

    }

    componentDidMount() {
        this.createAutocomplete('autoCompleteInput', this.onSearchChange)
    }

    onSearchChange = () => {
        const place = this.GoogleAutoComplete.getPlace();

        this.props.updateCoordinates(place.geometry.location.lat(), place.geometry.location.lng())
        const element = document.getElementById('autoCompleteInput')
        if (typeof(element) !== 'undefined' && element !== null) {
            ReactGA.event({
                category: 'LOCATION-PICKER',
                action  : 'Updated location',
                label   : element.value,
            });
        }

    };

    render() {
        const {locationPickerPlaceholder, title, disableTimeLocationUpdate} = this.props;

        return (
            <div style={{
                height        : '100%',
                display       : 'flex',
                alignItems    : 'center',
                justifyContent: 'center'
            }} className={"input-section " }>

                <div className="input-section-child">
                    <label className="registration-form-label">{title}</label>
                    <div className="ui fluid search">
                        <div className="ui fluid icon input">
                            <input disabled={disableTimeLocationUpdate} id="autoCompleteInput" style={{fontSize: 16}}
                                   placeholder={locationPickerPlaceholder} name="location" type="text"
                                   className="prompt"/>
                            <i aria-hidden="true" className="search icon"/>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    createAutocomplete = (inputFieldId, onchange) => {

        if (!this.GoogleSessionToken) {
            this.GoogleSessionToken = new google.maps.places.AutocompleteSessionToken()
        }
        const options = {
            sessionToken: this.GoogleSessionToken
        };

        this.GoogleAutoComplete = new google.maps.places.Autocomplete(document.getElementById(inputFieldId), options);
        this.GoogleAutoComplete.setFields(['geometry']);
        this.GoogleAutoComplete.addListener('place_changed', onchange);
    };
}


