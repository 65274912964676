import React from "react";
import PropTypes from "prop-types";
import {createNightStars, refreshCanvas, setCalculationData} from "./generators/NightStars";
import PreviewImage from "./supers/PreviewImage";
import moment from "moment";
import ReactGA from "react-ga";

export default class NightStarsPreviewImage extends PreviewImage {

    static propTypes = {
        showingConstellationLines : PropTypes.bool,
        showingConstellationLabels: PropTypes.bool,
        showingStarNames          : PropTypes.bool,
        showingDeepSkyObjects     : PropTypes.bool,
        nightStarColor            : PropTypes.string,
    };

    componentDidMount() {
        const startTime = Number(moment().format('x'));

        createNightStars(this.props)
        ReactGA.timing({
            category: 'STARS-PREVIEW',
            variable: 'mount',
            value   : Number(moment().format('x')) - startTime,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            dateTime, longitude, latitude,
            backgroundColor, showingDeepSkyObjects, showingStarNames,
            showingConstellationLabels, showingConstellationLines, imageBackgroundColor, nightStarColor
        } = this.props;

        if (prevProps.dateTime !== dateTime
            || prevProps.latitude !== latitude
            || prevProps.longitude !== longitude
            || prevProps.backgroundColor !== backgroundColor
            || prevProps.showingDeepSkyObjects !== showingDeepSkyObjects
            || prevProps.showingStarNames !== showingStarNames
            || prevProps.showingConstellationLabels !== showingConstellationLabels
            || prevProps.showingConstellationLines !== showingConstellationLines
            || prevProps.imageBackgroundColor !== imageBackgroundColor
            || prevProps.nightStarColor !== nightStarColor

        ) {
            const startTime = Number(moment().format('x'));

            setCalculationData(this.props);
            refreshCanvas({
                imageBackgroundColor: imageBackgroundColor,
                nightStarColor      : nightStarColor
            });
            ReactGA.timing({
                category: 'STARS-PREVIEW',
                variable: 'refresh',
                value   : Number(moment().format('x')) - startTime,
            });

        }
        return true
    }

    render() {
        const {imageBorderColor, showImageBorder} = this.props;

        return this.renderPreviewImage(
            <canvas id="nightStarsCanvas" width="2300" height="2300" style={{
                border      : '3px solid ' + (showImageBorder ? imageBorderColor : 'rgba(0,0,0,0)' ),
                height      : '95%',
                width       : '95%',
                borderRadius: '50%',
                pointerEvents: 'none'
            }}/>
        )
    }

}
