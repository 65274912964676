import React from "react";
import PropTypes from "prop-types";
import {TextArea} from "semantic-ui-react";

export default class FormTextArea extends React.Component {

  static propTypes = {
    value      : PropTypes.any,
    icon       : PropTypes.any,
    updateField: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    title      : PropTypes.string,
    fieldId    : PropTypes.string.isRequired,
  };


  onChange = (fieldId, value) => {
    if ((value.match(/\n/g) || []).length < 4) {
      this.props.updateField(fieldId, value);
    }
  };
  render() {
    const {placeholder, value, title, fieldId} = this.props;

      return (
      <div style={{
        height        : '100%',
        display       : 'flex',
        alignItems    : 'center',
        justifyContent: 'center'
      }} className={"input-section " }>
          <div className="input-section-child">
          <label className="registration-form-label">{title}</label>
          <div className="ui form">
          <TextArea
            style={{
              width     : '100%',
                fontSize: 16
            }}
            rows={4}
            name="message" placeholder={placeholder}
            value={value}
            onChange={(event) => this.onChange(fieldId, event.target.value)}
          />
          </div>
        </div>
      </div>
    )
  }

}
