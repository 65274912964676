import React from "react";
import PropTypes from "prop-types";
import ContactForm from "./ContactForm";

export default class Error extends React.Component {
  static propTypes = {
    status      : PropTypes.number.isRequired,
    message     : PropTypes.string.isRequired,
    title       : PropTypes.string.isRequired,
    tryAgainText: PropTypes.string.isRequired,
  };
  componentDidMount() {

    window.addEventListener("resize", this.resize);
    this.resize();
  }

  resize = () => {
    if (this.refs.errorbox) {

      this.setState({height: this.refs.errorbox.offsetWidth});
    }
  };
    state = {height: 300, contactFormOpen: false};

  contactSupport = () => {
      this.setState({contactFormOpen: true})
  };


  render() {
    const {title, message, status, tryAgainText} = this.props;
      const {height, contactFormOpen} = this.state;
    return (
      <div ref="errorbox" className="box-container" style={{height: height}}>
        <div className="error-box">
          <div className="face">
            <div className="eye"/>
            <div className="eye right"/>
            <div className="mouth sad"/>
          </div>
          <div className="shadow scale"/>
          <div className="message">
            <h1 className="alert">{title}</h1>
            <p>{message}</p>
            {status > 499 && <b>{tryAgainText}</b>}
            <button onClick={this.contactSupport} className="button-box">
              <h1 className="red">contact support</h1>
            </button>
          </div>
        </div>
          {contactFormOpen && <ContactForm close={() => this.setState({contactFormOpen: false})}/>}
      </div>
    )
  }
}



