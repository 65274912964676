import Moment from "moment";

const FRAME_COST = 50
export const updateField = (fieldId, value) => {
    return (dispatch) => {
        dispatch({
            type: LOAD,
            property: fieldId,
            payload: value,
        });
    }
};

export const updateCoordinates = (lat, lng) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_COORDINATES,
            lat: lat,
            lng: lng,
        });
    }
};

export const selectColorTheme = (config) => {
    return (dispatch) => {
        dispatch({
            type: SELECT_COLOR_THEME,
            payload: config,
        });

    }
};
export const updateIncludeFrame = (includeFrame) => {
    return (dispatch) => {
        if (includeFrame) {
            dispatch(updateTotalAmount(FRAME_COST))
        } else {
            dispatch(updateTotalAmount(-FRAME_COST))
        }

        dispatch(updateField('includeFrame', includeFrame))
    };
}
export const updateTotalAmount = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: LOAD,
            property: totalAmount,
            payload: getState().orderReducer.totalAmount + value,
        });
    }
};


const LOAD = 'LOAD';
const UPDATE_COORDINATES = 'UPDATE_COORDINATES';
const SELECT_COLOR_THEME = 'SELECT_COLOR_THEME';

const title = 'title';
const locationText = 'locationText';
const dateTime = 'dateTime';
const personalMessage = 'personalMessage';
const theme = 'theme';
const fontColor = 'fontColor';
const frameColor = 'frameColor';
const borderColor = 'borderColor';
const imageBorderColor = 'imageBorderColor';
const backgroundColor = 'backgroundColor';
const showingConstellationLabels = 'showingConstellationLabels';
const showingDeepSkyObjects = 'showingDeepSkyObjects';
const showingStarNames = 'showingStarNames';
const showingConstellationLines = 'showingConstellationLines';
const showImageBorder = 'showImageBorder';
const imageBackgroundColor = 'imageBackgroundColor';
const nightStarColor = 'nightStarColor';
const showFrameBorder = 'showFrameBorder';
const showEasternHemisphere = 'showEasternHemisphere';
const transparentUnlitMoon = 'transparentUnlitMoon';
const showMapLines = 'showMapLines';
const showLatLngText = 'showLatLngText';
const paymentStatus = 'paymentStatus';
const showClouds = 'showClouds';
const showAnnotation = 'showAnnotation';

const includeFrame = 'includeFrame';
const latitude = 'latitude';
const longitude = 'longitude';
const totalAmount = 'totalAmount';
const selectedColorTheme = 'selectedColorTheme';

const initialState = {
    [theme]: null,
    [paymentStatus]: null,
    [longitude]: -94.5785,
    [latitude]: 39.0997,
    [title]: 'My Moment',
    [locationText]: "Kansas City, Missouri",
    [dateTime]: Moment(),
    [personalMessage]: '\nTREASURE THIS MOMENT',
    [fontColor]: '#fff',
    [frameColor]: '#ecf0f2',
    [imageBorderColor]: '#ecf0f2',
    [borderColor]: '#fff',
    [backgroundColor]: '#191d28',
    [imageBackgroundColor]: "#191d28",
    [nightStarColor]: "#fff",
    'size': {
        key: '20x30',
        text: '20" x 30" - $59.99',
        value: '20x30',
        price:59.99,
        display:'20" x 30"'
    },
    [showingConstellationLines]: true,
    [showingConstellationLabels]: true,
    [showingStarNames]: true,
    [showEasternHemisphere]: false,
    [showImageBorder]: true,
    [showFrameBorder]: true,
    [showLatLngText]: true,
    [showingDeepSkyObjects]: true,
    [transparentUnlitMoon]: false,
    [showMapLines]: false,
    [includeFrame]: false,
    [showClouds]: true,
    [showAnnotation]: true,
    [totalAmount]: 50,
    [selectedColorTheme]: 2,
};


export const COLOR_THEMES = [
    {
        id: 1,
        name: 'Storm',
        [fontColor]: '#fff',
        [frameColor]: '#fff',
        [imageBorderColor]: '#fff',
        [borderColor]: '#fff',
        [backgroundColor]: '#384656',
        [imageBackgroundColor]: "#384656",
    },
    {
        id: 2,
        name: 'Midnight',
        [fontColor]: '#fff',
        [frameColor]: '#fff',
        [imageBorderColor]: '#fff',
        [borderColor]: '#fff',
        [backgroundColor]: '#191d28',
        [imageBackgroundColor]: "#191d28",
    },
    {
        id: 3,
        name: 'Aqua',
        [fontColor]: '#fff',
        [frameColor]: '#fff',
        [imageBorderColor]: '#fff',
        [borderColor]: '#fff',
        [backgroundColor]: '#164b58',
        [imageBackgroundColor]: "#164b58",
    },
    {
        id: 4,
        name: 'Black',
        [fontColor]: '#fff',
        [frameColor]: '#fff',
        [imageBorderColor]: '#fff',
        [borderColor]: '#fff',
        [backgroundColor]: '#000000',
        [imageBackgroundColor]: "#000000",
    },
    {
        id: 5,
        name: 'Storm White',
        [fontColor]: '#384656',
        [frameColor]: '#384656',
        [imageBorderColor]: '#384656',
        [borderColor]: '#384656',
        [imageBackgroundColor]: "#384656",
        [backgroundColor]: '#fff',
    },
    {
        id: 6,
        name: 'Midnight White',
        [fontColor]: '#191d28',
        [frameColor]: '#191d28',
        [imageBorderColor]: '#191d28',
        [borderColor]: '#191d28',
        [imageBackgroundColor]: "#191d28",
        [backgroundColor]: '#fff',
    },
    {
        id: 7,
        name: 'Aqua White',
        [fontColor]: '#164b58',
        [frameColor]: '#164b58',
        [imageBorderColor]: '#164b58',
        [borderColor]: '#164b58',
        [imageBackgroundColor]: "#164b58",
        [backgroundColor]: '#fff',
    },
    {
        id: 8,
        name: 'On White',
        [fontColor]: '#000000',
        [frameColor]: '#000000',
        [imageBorderColor]: '#000000',
        [borderColor]: '#000000',
        [imageBackgroundColor]: "#000000",
        [backgroundColor]: '#fff',
    },

]


const orderReducer = (state = initialState, action) => {

    switch (action.type) {
        case LOAD : {
            return {
                ...state,
                [action.property]: action.payload
            };
        }
        case UPDATE_COORDINATES : {
            return {
                ...state, [latitude]: action.lat, [longitude]: action.lng
            };
        }
        case SELECT_COLOR_THEME : {
            return {
                ...state, [selectedColorTheme]: action.payload.id, ...action.payload
            };
        }
        default:
            return state;
    }

}


export default orderReducer