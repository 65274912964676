import React from "react";
import {
    Header,
    Icon,
    List,
    Modal,
    Segment
} from "semantic-ui-react";

export const FAQ = close => <Modal open={true} onClose={close} closeIcon>

        <Header as='h2' attached='top'>
            <Icon name='question circle' color="red"/>
            <Header.Content>
                Frequently Asked Questions
            </Header.Content>
        </Header>
    <Modal.Content>
        <Segment raised>
            <List divided relaxed>
                <List.Item>
                    <List.Content>
                        <List.Header>Where is your business located?</List.Header>
                        <List.Description>Des Moines, Iowa</List.Description>
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Content>
                        <List.Header>What size are the prints?</List.Header>
                        <List.Description>20 inches wide by 30 inches tall</List.Description>
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Content>
                        <List.Header>What forms of payments do you accept?</List.Header>
                        <List.Description>PayPal, Debit and Credit Cards through PayPal's guest
                            checkout</List.Description>
                    </List.Content>
                </List.Item>
            </List>
        </Segment>
    </Modal.Content>
</Modal>