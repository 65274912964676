import React from "react";
import PropTypes from "prop-types";

export default class Loading extends React.Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
    title  : PropTypes.string.isRequired,
  };

  componentDidMount() {

    window.addEventListener("resize", this.resize);
    this.resize();
  }

  resize = () => {
    if (this.refs.successbox) {
      this.setState({height: this.refs.successbox.offsetWidth});
    }
  };
  state = {height: 300};

  render() {
    const {title, message} = this.props;
    const {height} = this.state;
    return (
      <div ref="successbox" className="box-container" style={{height: height}}>
        <div className="success-box">
          <div className="face2">
            <div className="eye"/>
            <div className="eye right"/>
            <div className="mouth happy"/>
          </div>
          <div className="shadow move"/>
          <div className="message">
            <h1 className="alert">{title}</h1>
            <p>{message}</p>
          </div>
        </div>
      </div>
    )
  }
}



