import React from "react";
import sequence from "sequencejs";
import {Icon, Label, Responsive, Segment} from "semantic-ui-react";
import MoonImage from "./images/moonPoster.jpg";
import NightStarsImage from "./images/starsWhitsm.jpg";
import Earth from "./images/earthPoster.jpg";
import MoonRoom from "./images/moonRoom.jpg";
import StarRoom from "./images/starRoom.jpg";
import EarthRoom from "./images/earthRoom.jpeg";


export default class SequenceBanner extends React.Component {
    componentDidMount() {
        sequence(document.getElementById('sequence'), {
            animateCanvas: false,
            preloader: true,
            phaseThreshold: false,
            startingStepAnimatesIn: true,
            reverseWhenNavigatingBackwards: true,
        });
    }

    render() {
        return (
            <div id="sequence" className="seq">

                <div className="seq-screen">
                    <ul className="seq-canvas">

                        <li className="seq-in">
                            <div className="seq-bg">
                                <Responsive minWidth={640}>
                                    <img data-seq alt="Earth"
                                         src={EarthRoom}/>
                                </Responsive>
                            </div>
                            <div className="seq-model">
                                <img data-seq alt="Earth" className={'seg-model-img'} src={Earth}/>
                            </div>

                            {SeqTitle(
                                'rgb(17,23,36)',
                                'globe',
                                'Blue Marble',
                                <p>Center the Earth to any<br/> treasured location.</p>
                            )}

                        </li>
                        <li>
                            <div className="seq-bg">
                                <Responsive minWidth={640}>

                                    <img data-seq alt="Earth"
                                         src={MoonRoom}/>
                                </Responsive>
                            </div>
                            <div className="seq-model">
                                <img data-seq alt="Earth" className={'seg-model-img'}
                                     src={MoonImage}/>
                            </div>
                            {SeqTitle(
                                'rgb(118,118,118)',
                                'moon',
                                'Moon Phase',
                                <p>Observe the moon exactly as you would see it<br/> at any given time and location.</p>
                            )}
                        </li>

                        <li>
                            <div className="seq-bg">
                                <Responsive minWidth={640}>

                                    <img data-seq alt="Earth"
                                         src={StarRoom}/>
                                </Responsive>
                            </div>
                            <div className="seq-model">
                                <img data-seq alt="Earth" className={'seg-model-img'} src={NightStarsImage}/>
                            </div>
                            {SeqTitle(
                                'rgb(0,181,173)',
                                'star',
                                'Stars & Constellations',
                                <p>Recreate the night sky from your special <br/>time and place.</p>
                            )}
                        </li>


                    </ul>
                </div>

                <fieldset className="seq-nav" aria-controls="sequence" aria-label="Slider buttons">
                    <button type="button" className="seq-prev" aria-label="Previous">Previous</button>
                    <button type="button" className="seq-next" aria-label="Next">Next</button>
                </fieldset>

                <ul role="navigation" aria-label="Pagination" className="seq-pagination">

                    <li>
                        <a href="#step1" rel="step1" title="Go to slide 1"><img style={{height: 130}} alt="Earth"
                                                                                src={Earth}/></a>
                    </li>
                    <li><a href="#step2" rel="step2" title="Go to slide 3"><img style={{height: 130}}
                                                                                alt="Moon"
                                                                                src={MoonImage}/></a>
                    </li>
                    <li><a href="#step3" rel="step3" title="Go to slide 2"><img style={{height: 130}}
                                                                                alt="Stars" src={NightStarsImage}/></a>
                    </li>

                </ul>
            </div>
        )
    }


}

const SeqTitle = (color, icon, title, description) => (
    <div className="seq-title">
        <h2 data-seq>
            <Label style={{
                backgroundColor: color,
                color: '#fff'
            }}
                   size={'large'} className={'seq-label'}>{title}
                <Icon name={icon}
                      style={{margin: '0 0 0 .5em'}}/>
            </Label>
        </h2>
        <h3 data-seq>
            <Segment className={'seq-desc-segment'} raised>{description}</Segment>
        </h3>
    </div>
)