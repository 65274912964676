import React from "react";
import {Label} from "semantic-ui-react";

type Props = {
    title: string,
    backgroundColor: string,
    imageBackgroundColor: string,
    borderColor: string,
    frameColor: string,
    fontColor: string,
    locationText: string,
    latitude: any,
    longitude: any,
    dateTime: any,
    showFrameBorder: boolean,
    showLatLngText: boolean,
    theme: object,
    size: any,
    personalMessage: string,
}

type State = {
    width: number;
    resizing: boolean,
    unselectedImageWidth: number,
    unselectedImgResizing: boolean
}

export default class PreviewImage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            width: this.getContainerWidth(),
            resizing: false,
            unselectedImageWidth: 400,
            unselectedImgResizing: false
        }
        window.addEventListener("resize", this.updateWidth);
    }


    getContainerWidth = () => {
        const element = document.getElementById('preview-image-container')
        if (element && element.offsetWidth && !isNaN(element.offsetWidth)) {
            return element.offsetWidth;
        }
        return 400;
    }

    updateWidth = () => {
        const {resizing} = this.state
        if (!resizing) {
            this.setState({resizing: true})
            setTimeout(() => {
                this.setState({width: this.getContainerWidth(), resizing: false});
            }, 250)
        }

    }

    renderMessage = (personalMessageArray: string[]) => [0, 1, 2, 3].map((index) => (

            <div key={index} className="image-text-lg" style={index === 0 ? {paddingTop: 10} : {paddingTop: 0}}>
                {personalMessageArray.length > index ? personalMessageArray[index] : ''}
                &nbsp;
            </div>
        )
    )


    renderPreviewImage = (image: string) => {

        const {backgroundColor, size, borderColor, title, theme, fontColor, showLatLngText, locationText, dateTime, latitude, longitude, personalMessage, showFrameBorder} = this.props;
        const {width} = this.state
        return (
            <div className="frame-segment-container" id="preview-image-container">

                <div className="figure">
                    <div className="frame-container" style={{
                        backgroundColor: backgroundColor,

                    }}>

                        <div id="preview-frame-border"
                             className={showFrameBorder ? "frame-border no-frame-border" : "frame-border"} style={{
                            border: '3px solid ' + (showFrameBorder ? borderColor : 'rgba(0,0,0,0)'),
                            height: 1.4 * width
                        }}>
                            <div>
                                {image}
                                <div style={{
                                    color: fontColor,
                                }}>
                                    {this.renderMessage(personalMessage.split("\n"))}
                                    <div className="image-text first-image-text">
                                        {title}&nbsp;
                                    </div>
                                    <div className="image-text">
                                        {dateTime.format("Do of MMMM, YYYY") + ' at ' + dateTime.format("h:mm a")}
                                    </div>
                                    <div className="image-text">
                                        {showLatLngText ? getNorthSouthFromLat(Number(latitude)) + getEastWestFromLng(Number(longitude)) : locationText}&nbsp;
                                    </div>
                                    <div className="image-text">
                                        {showLatLngText ? locationText : ''}&nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Label color="blue" basic={theme === null} className="fluid" size="large" style={{
                        borderTop: 'none',
                        margin: 0,
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0
                    }}>{size.display}</Label>
                </div>
            </div>
        )
    }
}

const getNorthSouthFromLat = (latitude: any) => latitude > 0 ? latitude.toFixed(4) + '° N   ' : (latitude.toFixed(4) * -1) + '° S   ';
const getEastWestFromLng = (lng: any) => lng > 0 ? lng.toFixed(4) + '° E' : (lng.toFixed(4) * -1) + '° W';


