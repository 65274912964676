import React from "react";
import PropTypes from "prop-types";
import PreviewImage from "./supers/PreviewImage";
import Error from "./Error";
import Loading from "./Loading";
import {Image} from "semantic-ui-react";
import moment from "moment";

export default class MoonPreviewImage extends PreviewImage {

    static propTypes = {
        transparentUnlitMoon: PropTypes.bool.isRequired,
    };

    componentDidMount() {
        if ((!this.props.moonImage || this.props.moonImageDate !== moment(this.props.dateTime).clone().utc().format('YYYY-MM-DD HH:mm:ss'))) {
            this.props.getMoonImage(this.props.dateTime, this.props.latitude, this.props.longitude)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {dateTime, moonImageLoading, longitude, latitude, transparentUnlitMoon} = this.props;

        if (!moonImageLoading &&
            (prevProps.dateTime !== dateTime || prevProps.latitude !== latitude || prevProps.longitude !== longitude || prevProps.transparentUnlitMoon !== transparentUnlitMoon)) {

            this.props.getMoonImage(dateTime, latitude, longitude)
        }
        return true
    }

    render() {
        const {moonImage, moonImageLoading, moonImageError, imageBorderColor, showImageBorder, dateTime, latitude, longitude} = this.props;

        if (moonImageError) {
            return this.renderPreviewImage(<Error {...moonImageError}/>);

        } else if (moonImageLoading) {

            return this.renderPreviewImage(
                <Loading
                    message={"Calculating sunlit portion of moon for latitude (" + Number(latitude).toFixed(4) + ") " +
                    "and longitude (" + Number(longitude).toFixed(4) + ") on the "
                    + moment(dateTime).clone().format('Do of MMMM YYYY @ h:mm a')} title="Rendering Moon"/>
            )
        }

        return this.renderPreviewImage(
            <Image centered circular src={moonImage} style={{
                border      : '3px solid ' + (showImageBorder ? imageBorderColor : 'rgba(0,0,0,0)' ),
                height      : '95%',
                width       : '95%',
                borderRadius: '50%',
                pointerEvents: 'none'
            }}/>
        )

    }

}