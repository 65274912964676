/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import {Grid, Header, List, Segment} from "semantic-ui-react";
import {AboutUs} from "./AboutUs";
import {FAQ} from "./FAQ";
import Gallery from "./Gallery";
import ContactForm from "./ContactForm";

export default class Footer extends React.Component {

    state = {
        contactFormOpen: false,
        aboutUsOpen    : false,
        faqOpen        : false,
        galleryType    : null,
    }

    render() {
        const {galleryType, contactFormOpen, faqOpen, aboutUsOpen} = this.state;
        return (

            <Segment vertical inverted style={{padding: '1.5em 0em'}}>
                <Grid container divided inverted stackable centered>
                        <Grid.Row>
                            <Grid.Column width={3} textAlign="center">
                                <Header inverted as='h4' content='About'/>
                                <List link inverted>
                                    <List.Item onClick={() => this.setState({aboutUsOpen: true})} as='a'>Who We Are</List.Item>

                                </List>
                            </Grid.Column>
                            {/*<Grid.Column width={3} textAlign="center">
                                <Header inverted as='h4' content='Gallery'/>
                                <List link inverted>
                                    <List.Item as='a'
                                               onClick={() => this.setState({galleryType: 'earth'})}>Earth</List.Item>
                                    <List.Item as='a'
                                               onClick={() => this.setState({galleryType: 'stars'})}>Stars</List.Item>
                                    <List.Item as='a'
                                               onClick={() => this.setState({galleryType: 'moon'})}>Moon</List.Item>
                                </List>
                             </Grid.Column>*/}
                            <Grid.Column width={3} textAlign="center">
                                <Header inverted as='h4' content='Help'/>
                                <List link inverted>
                                    <List.Item as='a' onClick={() => this.setState({contactFormOpen: true})}>Contact
                                        Us</List.Item>
                                    <List.Item as='a' onClick={() => this.setState({faqOpen: true})}>FAQ</List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column width={6} textAlign="center">
                                <Header as='h4' inverted>
                                    Our Mission
                                </Header>
                                <p style={{
                                    color  : '#9facb6',
                                    padding: '0 15px 0 15px'
                                }}>
                                    We treat every order as if it is our first customer. Our goal is to provide 100%
                                    satisfaction from start to finish.
                                    If you have any
                                    questions or concerns, please <a
                                    onClick={() => this.setState({contactFormOpen: true})}>contact us</a>.
                                </p>
                            </Grid.Column>
                        </Grid.Row>

                    </Grid>
                <p style={{
                    paddingTop: 25,
                    textAlign : 'center'
                }}>©2019 Glad Tech LLC. All rights reserved.</p>
                {galleryType && <Gallery close={() => this.setState({galleryType: null})} type={galleryType}/>}
                {contactFormOpen && <ContactForm close={() => this.setState({contactFormOpen: false})}/>}
                {faqOpen && FAQ(() => this.setState({faqOpen: false}))}
                {aboutUsOpen && AboutUs(() => this.setState({aboutUsOpen: false}))}
            </Segment>

        )
    }

}
