/* eslint-disable no-mixed-operators */

var STAR_COLOR = '#fff';
var Astro =
    {
        JD_J2000: 2451545.0,
        JD_1970: 2440587.5,
        YEARDAYS: 365.2422,
        EQtoECL: 1,
        ECLtoEQ: -1,

        range: function (v, r) {
            return v - r * Math.floor(v / r)
        },

        degrad: function (x) {
            return x * 1.74532925199433e-2
        },

        raddeg: function (x) {
            return x * 5.729577951308232e1
        },

        hrrad: function (x) {
            return x * 2.617993877991494e-1
        },

        radhr: function (x) {
            return x * 3.819718634205488
        },

        /* from[] and to[] contain (azimuth, altitude) and
         (hour angle, declination) */
        aa_hadec: function (lat, from, to) {
            const slat = Math.sin(lat);
            const clat = Math.cos(lat);
            const sx = Math.sin(from[0]);
            const cx = Math.cos(from[0]);
            const sy = Math.sin(from[1]);
            const cy = Math.cos(from[1]);

            to[0] = Math.atan2(-cy * sx, -cy * cx * slat + sy * clat);
            to[1] = Math.asin(sy * slat + cy * clat * cx)
        },

        /* from[] and to[] contain (lam, bet) and (ra, dec) */
        /* if sw = EQtoECL, from[] is (ra, dec) */
        ecl_eq: function (sw, from, to) {
            var eps = Astro.degrad(23.45229444);
            var seps = Math.sin(eps);
            var ceps = Math.cos(eps);

            var sy = Math.sin(from[1]);
            var cy = Math.cos(from[1]);
            if (Math.abs(cy) < 1e-20) {
                cy = 1e-20;
            }
            var ty = sy / cy;
            var cx = Math.cos(from[0]);
            var sx = Math.sin(from[0]);

            to[1] = Math.asin(( sy * ceps ) - ( cy * seps * sx * sw ));
            to[0] = Math.atan2((( sx * ceps ) + ( ty * seps * sw )), cx);
            to[0] = Astro.range(to[0], 2 * Math.PI)
        },

        precess: function (jd1, jd2, coord) {
            var zeta_A, z_A, theta_A;
            var T;
            var A, B, C;
            var alpha, delta;
            var alpha_in, delta_in;
            var from_equinox, to_equinox;
            var alpha2000, delta2000;

            from_equinox = ( jd1 - Astro.JD_J2000 ) / Astro.YEARDAYS;
            to_equinox = ( jd2 - Astro.JD_J2000 ) / Astro.YEARDAYS;
            alpha_in = coord[0];
            delta_in = coord[1];

            /* From from_equinox to 2000.0 */

            if (from_equinox !== 0.0) {
                T = from_equinox / 100.0;
                zeta_A = Astro.degrad(T * ( 0.6406161 + T * (  8.39e-5 + T * 5.0e-6 )));
                z_A = Astro.degrad(T * ( 0.6406161 + T * (  3.041e-4 + T * 5.1e-6 )));
                theta_A = Astro.degrad(T * ( 0.5567530 + T * ( -1.185e-4 + T * 1.16e-5 )));

                A = Math.sin(alpha_in - z_A) * Math.cos(delta_in);
                B = Math.cos(alpha_in - z_A)
                    * Math.cos(theta_A) * Math.cos(delta_in)
                    + Math.sin(theta_A) * Math.sin(delta_in);
                C = -Math.cos(alpha_in - z_A)
                    * Math.sin(theta_A) * Math.cos(delta_in)
                    + Math.cos(theta_A) * Math.sin(delta_in);

                alpha2000 = Math.atan2(A, B) - zeta_A;
                alpha2000 = Astro.range(alpha2000, 2 * Math.PI);
                delta2000 = Math.asin(C)
            }
            else {
                alpha2000 = alpha_in;
                delta2000 = delta_in
            }

            /* From 2000.0 to to_equinox */

            if (to_equinox !== 0.0) {
                T = to_equinox / 100.0;
                zeta_A = Astro.degrad(T * ( 0.6406161 + T * (  8.39e-5 + T * 5.0e-6 )));
                z_A = Astro.degrad(T * ( 0.6406161 + T * (  3.041e-4 + T * 5.1e-6 )));
                theta_A = Astro.degrad(T * ( 0.5567530 + T * ( -1.185e-4 + T * 1.16e-5 )));

                A = Math.sin(alpha2000 + zeta_A) * Math.cos(delta2000);
                B = Math.cos(alpha2000 + zeta_A)
                    * Math.cos(theta_A) * Math.cos(delta2000)
                    - Math.sin(theta_A) * Math.sin(delta2000);
                C = Math.cos(alpha2000 + zeta_A)
                    * Math.sin(theta_A) * Math.cos(delta2000)
                    + Math.cos(theta_A) * Math.sin(delta2000);

                alpha = Math.atan2(A, B) + z_A;
                alpha = Astro.range(alpha, 2.0 * Math.PI);
                delta = Math.asin(C)
            }
            else {
                alpha = alpha2000;
                delta = delta2000
            }

            coord[0] = alpha;
            coord[1] = delta
        }
    };

/*
 ======================================================================
 observer.js

 Ernie Wright  2 June 2013
 ====================================================================== */

function Observer() {
    const d = new Date();
    this.jd = Astro.JD_1970 + d.getTime() / 86400000.0;
    this.longitude = Astro.degrad(-0.25 * d.getTimezoneOffset());
    this.latitude = Astro.degrad(40.0);
    this.initLST()
}

Observer.prototype.setJD = function (jd) {
    this.jd = jd;
    this.initLST()
};

Observer.prototype.getDate = function () {
    return new Date(Math.round(( this.jd - Astro.JD_1970 ) * 86400000.0))
};

Observer.prototype.setDate = function (date) {
    this.jd = Astro.JD_1970 + date.getTime() / 86400000.0;
    this.initLST()
};

Observer.prototype.incHour = function (count) {
    this.jd += count / 24.0;
    this.initLST()
};

Observer.prototype.getLatDegrees = function () {
    return Math.round(Astro.raddeg(this.latitude))
};

Observer.prototype.setLatDegrees = function (lat) {
    this.latitude = Astro.degrad(lat)
};

Observer.prototype.getLonDegrees = function () {
    return Math.round(Astro.raddeg(this.longitude))
};

Observer.prototype.setLon = function (lon) {
    this.longitude = lon;
    this.initLST()
};

Observer.prototype.setLonDegrees = function (lon) {
    this.longitude = Astro.degrad(lon);
    this.initLST()
};

Observer.prototype.jd_day = function () {
    return Math.floor(this.jd - 0.5) + 0.5
};

Observer.prototype.jd_hour = function () {
    return ( this.jd - this.jd_day() ) * 24.0
};

Observer.prototype.initLST = function () {
    this.lst = Astro.range(this.gst() + this.longitude, 2 * Math.PI)
};

Observer.prototype.gst = function () {
    var t = ( this.jd_day() - Astro.JD_J2000 ) / 36525;
    var theta = 1.753368559146 + t * ( 628.331970688835
        + t * ( 6.770708e-6 + t * -1.48e-6 ));
    return Astro.range(theta + Astro.hrrad(this.jd_hour()), 2 * Math.PI)
};

/*
 ======================================================================
 skypos.js

 Ernie Wright  2 June 2013
 ====================================================================== */

function skypos_transform(pos, now, w, h) {
    var coord = [
        pos.ra,
        pos.dec
    ];
    Astro.precess(Astro.JD_J2000, now.jd, coord);
    coord[0] = now.lst - coord[0];
    Astro.aa_hadec(now.latitude, coord, coord);
    if (coord[1] < 0) {
        pos.visible = false;
    } else {
        pos.visible = true;
        var tmp = 0.5 - coord[1] / Math.PI;
        pos.x = w * ( 0.5 - tmp * Math.sin(coord[0]));
        pos.y = h * ( 0.5 - tmp * Math.cos(coord[0]))
    }
    return coord
}

function init_stars(star) {
    let len = star.length;
    for (let i = 0; i < len; i++) {
        if (star[i].mag < 3.5) {
            let cindex = Math.round(8 * ( star[i].bv + 0.4 ) / 2.4);
            cindex = Math.max(0, Math.min(8, cindex));
            star[i].color = STAR_COLOR;
            star[i].radius = 3.1 - 0.6 * star[i].mag;   // 1.0 to 4.0
            star[i].bright = true
        }
        else {
            let gray = 160 - Math.round(( star[i].mag - 3.5 ) * 80.0);
            star[i].color = "#" + ( 1 << 24 | gray << 16 | gray << 8 | gray ).toString(16).slice(1);
            star[i].radius = Math.random() + .4;
            star[i].bright = false
        }
    }
}


function init_planets(planet) {
    const seps = 0.397777156;
    const ceps = 0.917482062;

    let so, co, si, ci, sw, cw, f1, f2;

    for (let i = 0; i < 9; i++) {
        so = Math.sin(planet[i].o);
        co = Math.cos(planet[i].o);
        si = Math.sin(planet[i].i);
        ci = Math.cos(planet[i].i);
        sw = Math.sin(planet[i].wb - planet[i].o);
        cw = Math.cos(planet[i].wb - planet[i].o);

        f1 = cw * so + sw * co * ci;
        f2 = cw * co * ci - sw * so;

        planet[i].P = [];
        planet[i].Q = [];
        planet[i].P[0] = cw * co - sw * so * ci;
        planet[i].P[1] = ceps * f1 - seps * sw * si;
        planet[i].P[2] = seps * f1 + ceps * sw * si;
        planet[i].Q[0] = -sw * co - cw * so * ci;
        planet[i].Q[1] = ceps * f2 - seps * cw * si;
        planet[i].Q[2] = seps * f2 + ceps * cw * si;

        switch (i) {
            case 2:
                planet[i].radius = 5;
                break;
            case 8:
                planet[i].radius = 2;
                break;
            default:
                planet[i].radius = 3;
                break
        }
        planet[i].bright = true
    }
}

function find_planet(planet, earth, jd) {
    function kepler(m, e) {
        const EPSILON = 1.0e-6;
        let d, ae = m;

        while (true) {
            d = ae - ( e * Math.sin(ae)) - m;
            if (Math.abs(d) < EPSILON) {
                break;
            }
            d /= 1.0 - ( e * Math.cos(ae));
            ae -= d
        }
        return 2.0 *
            Math.atan(Math.sqrt(( 1.0 + e ) / ( 1.0 - e )) * Math.tan(ae / 2.0))
    }

    const t = ( jd - Astro.JD_J2000 ) / 36525.0;
    let m = planet.L - planet.wb + planet.dL * t;
    /* mean anomaly */
    m = Astro.range(m, Math.PI * 2.0);

    const v = kepler(m, planet.e);
    const cv = Math.cos(v);
    const sv = Math.sin(v);
    const r = ( planet.a * ( 1.0 - planet.e * planet.e )) / ( 1 + planet.e * cv );

    planet.hx = r * ( planet.P[0] * cv + planet.Q[0] * sv );
    planet.hy = r * ( planet.P[1] * cv + planet.Q[1] * sv );
    planet.hz = r * ( planet.P[2] * cv + planet.Q[2] * sv );

    let dx, dy, dz;
    if (planet.name !== 'Earth') {
        dx = planet.hx - earth.hx;
        dy = planet.hy - earth.hy;
        dz = planet.hz - earth.hz
    } else {
        dx = -planet.hx;
        dy = -planet.hy;
        dz = -planet.hz
    }

    planet.pos.ra = Math.atan2(dy, dx);
    planet.pos.dec = Math.atan2(dz, Math.sqrt(dx * dx + dy * dy))
}

function find_moon(moon, earth, jd) {
    const P2 = Math.PI * 2.0;
    const ARC = 206264.8062;
    let T, L0, L, LS, D, F, DL, S, H, N;
    let mlon, mlat;

    /* calculate the Moon's ecliptic longitude and latitude */
    T = ( jd - 2451545.0 ) / 36525.0;

    L0 = Astro.range(0.606433 + 1336.855225 * T, 1.0);
    L = P2 * Astro.range(0.374897 + 1325.552410 * T, 1.0);
    LS = P2 * Astro.range(0.993133 + 99.997361 * T, 1.0);
    D = P2 * Astro.range(0.827361 + 1236.853086 * T, 1.0);
    F = P2 * Astro.range(0.259086 + 1342.227825 * T, 1.0);

    DL = 22640 * Math.sin(L) +
        -4586 * Math.sin(L - 2 * D) +
        2370 * Math.sin(2 * D) +
        769 * Math.sin(2 * L) +
        -668 * Math.sin(LS) +
        -412 * Math.sin(2 * F) +
        -212 * Math.sin(2 * L - 2 * D) +
        -206 * Math.sin(L + LS - 2 * D) +
        192 * Math.sin(L + 2 * D) +
        -165 * Math.sin(LS - 2 * D) +
        -125 * Math.sin(D) +
        -110 * Math.sin(L + LS) +
        148 * Math.sin(L - LS) +
        -55 * Math.sin(2 * F - 2 * D);

    S = F + ( DL + 412 * Math.sin(2 * F) + 541 * Math.sin(LS)) / ARC;
    H = F - 2 * D;
    N = -526 * Math.sin(H) +
        44 * Math.sin(L + H) +
        -31 * Math.sin(-L + H) +
        -23 * Math.sin(LS + H) +
        11 * Math.sin(-LS + H) +
        -25 * Math.sin(-2 * L + F) +
        21 * Math.sin(-L + F);

    /* epoch of date! */
    mlon = P2 * Astro.range(L0 + DL / 1296000.0, 1.0);
    mlat = ( 18520.0 * Math.sin(S) + N ) / ARC;

    /* convert Sun equatorial J2000 to ecliptic coordinates at epoch jd */
    /* "Earth" ra and dec are really geocentric Sun coordinates */
    const coord = [
        earth.pos.ra,
        earth.pos.dec
    ];
    Astro.ecl_eq(Astro.EQtoECL, coord, coord);
    Astro.precess(Astro.JD_J2000, jd, coord);

    /* calculate Moon phase */
    D = mlon - coord[0];
    moon.phase = Math.acos(Math.cos(D) * Math.cos(mlat));
    if (Math.sin(D) < 0.0) {
        moon.phase = P2 - moon.phase;
    }
    moon.phase -= Math.PI;

    /* convert Moon ecliptic to equatorial coordinates */
    coord[0] = mlon;
    coord[1] = mlat;
    Astro.ecl_eq(Astro.ECLtoEQ, coord, coord);
    Astro.precess(jd, Astro.JD_J2000, coord);
    moon.pos.ra = coord[0];
    moon.pos.dec = coord[1];

    /* calculate position angle of the bright limb */
    const sa = Math.sin(earth.pos.ra - moon.pos.ra);
    const ca = Math.cos(earth.pos.ra - moon.pos.ra);
    const sd0 = Math.sin(earth.pos.dec);
    const cd0 = Math.cos(earth.pos.dec);
    const sd = Math.sin(moon.pos.dec);
    const cd = Math.cos(moon.pos.dec);

    moon.posAngle = Math.atan2(cd0 * sa, sd0 * cd - cd0 * sd * ca)
}

const STAR_DATA = require('./stars.json')
const star = STAR_DATA.stars;

const starname = STAR_DATA.starNames;

const conname = STAR_DATA.constellationNames;

const conline = STAR_DATA.constellationLines

const dso = STAR_DATA.dso

const planet = [
    {
        pos: {ra: 0, dec: 0}, name: 'Mercury', color: STAR_COLOR,
        a: 0.38709893, e: 0.20563069, i: 0.1222580, o: 0.8435468,
        wb: 1.3518701, L: 4.4026077, dL: 2608.79031222
    },
    {
        pos: {ra: 0, dec: 0}, name: 'Venus', color: STAR_COLOR,
        a: 0.72333199, e: 0.00677323, i: 0.0592489, o: 1.3383305,
        wb: 2.2956836, L: 3.1761455, dL: 1021.32855281
    },
    {
        pos: {ra: 0, dec: 0}, name: 'Earth', color: STAR_COLOR,
        a: 1.00000011, e: 0.01671022, i: 0.0000009, o: -0.1965352,
        wb: 1.7967674, L: 1.7534337, dL: 628.30757698
    },
    {
        pos: {ra: 0, dec: 0}, name: 'Mars', color: STAR_COLOR,
        a: 1.52366231, e: 0.09341233, i: 0.0322992, o: 0.8653088,
        wb: 5.8650191, L: 6.2038308, dL: 334.06137011
    },
    {
        pos: {ra: 0, dec: 0}, name: 'Jupiter', color: STAR_COLOR,
        a: 5.20336301, e: 0.04839266, i: 0.0227818, o: 1.7550359,
        wb: 0.2575033, L: 0.6004697, dL: 52.96627451
    },
    {
        pos: {ra: 0, dec: 0}, name: 'Saturn', color: STAR_COLOR,
        a: 9.53707032, e: 0.05415060, i: 0.0433620, o: 1.9847019,
        wb: 1.6132417, L: 0.8716928, dL: 21.33690681
    },
    {
        pos: {ra: 0, dec: 0}, name: 'Uranus', color: STAR_COLOR,
        a: 19.19126393, e: 0.04716771, i: 0.0134366, o: 1.2955558,
        wb: 2.9838889, L: 5.4669329, dL: 7.47848272
    },
    {
        pos: {ra: 0, dec: 0}, name: 'Neptune', color: STAR_COLOR,
        a: 30.06896348, e: 0.00858587, i: 0.0308778, o: 2.2989772,
        wb: 0.7848981, L: 5.3211603, dL: 3.81281337
    },
    {
        pos: {ra: 0, dec: 0}, name: 'Pluto', color: STAR_COLOR,
        a: 39.48168677, e: 0.24880766, i: 0.2991800, o: 1.9251587,
        wb: 3.9107027, L: 4.1700944, dL: 2.53435334
    }
];

const moon = {
    pos: {
        ra: 0,
        dec: 0
    }
};

var now = {};
var clipped = false;
var ck_starlabels = false;
var ck_conlabels = false;
var ck_dsos = true;
var ck_conlines = true;

function draw_star(context, s) {
    context.fillStyle = STAR_COLOR;
    context.strokeStyle = STAR_COLOR;
    context.beginPath();
    context.arc(s.pos.x, s.pos.y, s.radius * 2, 0, 2 * Math.PI);
    context.closePath();
    context.fill()
}

function draw_planet(context, p, shouldDrawLabel) {
    draw_star(context, p);
    if (shouldDrawLabel) {
        context.fillStyle = STAR_COLOR;
        context.strokeStyle = STAR_COLOR;
        context.font = '12px Sans-Serif';
        const name = p.name === 'Earth' ? 'Sun' : p.name;
        context.fillText(name, p.pos.x + 10, p.pos.y)
    }
}

function draw_star_label(context, p) {
    context.fillStyle = STAR_COLOR;
    context.strokeStyle = STAR_COLOR;
    context.font = '11px Sans-Serif';
    context.fillText(p.label, p.pos.x + 10, p.pos.y)
}

function draw_con_label(context, p) {
    context.fillStyle = STAR_COLOR;
    context.strokeStyle = STAR_COLOR;
    context.font = '10px Sans-Serif';
    const s = p.name.toUpperCase();
    const w = context.measureText(s).width;
    context.fillText(s, p.pos.x - w / 2, p.pos.y)
}

function ellipse(context, cx, cy, rx, ry, filled) {
    context.save();
    context.fillStyle = STAR_COLOR;
    context.strokeStyle = STAR_COLOR;
    context.beginPath();
    context.translate(cx - rx, cy - ry);
    context.scale(rx, ry);
    context.arc(1, 1, 1, 0, 2 * Math.PI, false);
    context.closePath();
    context.restore();
    if (filled) {
        context.fill();
    } else
        context.stroke()
}

function draw_dso(context, m) {
    context.fillStyle = STAR_COLOR;
    context.strokeStyle = STAR_COLOR;
    context.font = '10px Sans-Serif';
    context.fillText(m.name, m.pos.x + m.offsetx, m.pos.y + m.offsety);
    if (m.catalog === 1 && m.id === 45) {
        return;
    }
    switch (m.type) {
        case 1:
        case 2:
            context.fillStyle = STAR_COLOR;
            context.strokeStyle = STAR_COLOR;
            context.beginPath();
            context.arc(m.pos.x, m.pos.y, 2.5, 0, 2 * Math.PI);
            context.closePath();
            context.stroke();
            break;
        case 3:
        case 4:
        case 5:
            context.strokeRect(m.pos.x - 2, m.pos.y - 2, 4, 4);
            break;
        case 6:
            ellipse(context, m.pos.x, m.pos.y, 4, 2, true);
            break;
        default:
            context.fillStyle = STAR_COLOR;
            context.strokeStyle = STAR_COLOR;
            context.beginPath();
            context.moveTo(m.pos.x - 2, m.pos.y);
            context.lineTo(m.pos.x + 2, m.pos.y);
            context.moveTo(m.pos.x, m.pos.y - 2);
            context.lineTo(m.pos.x, m.pos.y + 2);
            context.stroke();
            break
    }
}

function draw_moon(context) {
    // context.globalCompositeOperation = 'source-over';
    //TODO:Draw moon
    // var i = Math.floor(( Astro.raddeg( moon.phase ) + 180 ) / 12 );
    // context.drawImage(document.getElementById('moonImage'), i * 16, 0, 16, 16, moon.pos.x - 8, moon.pos.y - 8, 16, 16);
    context.globalCompositeOperation = 'lighter';
    context.fillStyle = STAR_COLOR;
    context.strokeStyle = STAR_COLOR;
    context.font = '12px Sans-Serif';
    context.fillText('Moon', moon.pos.x + 8, moon.pos.y)
}

function draw_line(context, s1, s2) {
    if (s1.pos.visible && s2.pos.visible) {
        context.fillStyle = STAR_COLOR;
        context.strokeStyle = STAR_COLOR;
        context.beginPath();
        context.moveTo(s1.pos.x, s1.pos.y);
        context.lineWidth = 0.8;
        context.lineTo(s2.pos.x, s2.pos.y);
        context.stroke()
    }
}

function draw_sky(context, w, h, backgroundColor) {
    /* ----- calculate Earth (sun) position */
    find_planet(planet[2], null, now.jd);
    // let azalt = skypos_transform( planet[ 2 ].pos, now, w, h );
    /* let bgcolor;
     if ( azalt[ 1 ] > 0 ) bgcolor = "#191d29";              // 24, 36, 72
     else if ( azalt[ 1 ] > -0.10472 ) bgcolor = "#191d29";  // 18, 27, 54
     else if ( azalt[ 1 ] > -0.20944 ) bgcolor = "#191d29";  // 12, 18, 36
     else if ( azalt[ 1 ] > -0.31416 ) bgcolor = "#191d29";  //  6,  9, 18
     else bgcolor = "#191d29";*/

    context.globalCompositeOperation = 'source-over';
    context.lineWidth = 2;
    context.fillStyle = backgroundColor;// "rgba(255, 255, 255, 0.0)";//bgcolor;  //
    // planet[ 2 ].pos.visible ? "#182448"
    // : "#000000";
    context.beginPath();
    context.arc(w / 2, h / 2, w / 2, 0, 2 * Math.PI);

    context.closePath();
    context.fill();
    //context.stroke();
    if (!clipped) {
        context.clip();
        clipped = true
    }

    context.globalCompositeOperation = 'source-over';
    context.lineWidth = 1;

    /* ----- horizon labels */
    context.textBaseline = 'middle';
    context.fillStyle = STAR_COLOR;
    context.font = '12px Sans-Serif';

    /* ---- stars */
    let len = star.length;
    for (let i = 0; i < len; i++) {
        skypos_transform(star[i].pos, now, w, h);
        if (star[i].pos.visible)
            draw_star(context, star[i])
    }

    /* ---- star labels */
    if (ck_starlabels) {
        len = starname.length;
        for (let i = 0; i < len; i++) {
            skypos_transform(starname[i].pos, now, w, h);
            if (starname[i].pos.visible)
                draw_star_label(context, starname[i])
        }
    }

    /* ---- constellation labels */
    if (ck_conlabels) {
        len = conname.length;
        for (let i = 0; i < len; i++) {
            skypos_transform(conname[i].pos, now, w, h);
            if (conname[i].pos.visible)
                draw_con_label(context, conname[i])
        }
    }

    /* ---- constellation lines */
    if (ck_conlines) {
        context.strokeStyle = STAR_COLOR;
        len = conline.length;
        for (let i = 0; i < len; i++)
            draw_line(context, star[conline[i][0]], star[conline[i][1]]);
    }

    /* ---- DSOs */
    if (ck_dsos) {
        len = dso.length;
        for (let i = 0; i < len; i++) {
            skypos_transform(dso[i].pos, now, w, h);
            if (dso[i].pos.visible)
                draw_dso(context, dso[i])
        }

        /* ---- planets */
        for (let i = 0; i < 9; i++) {
            if (i !== 2) {
                find_planet(planet[i], planet[2], now.jd);
                skypos_transform(planet[i].pos, now, w, h)
            }
            if (planet[i].pos.visible) {
                draw_planet(context, planet[i], ck_starlabels)
            }
        }
        /* ----- Moon */
        find_moon(moon, planet[2], now.jd);
        skypos_transform(moon.pos, now, w, h);
        if (moon.pos.visible) {
            draw_moon(context)
        }

    }

}

export function refreshCanvas({imageBackgroundColor, nightStarColor}) {
    STAR_COLOR = nightStarColor;
    const canvas = document.getElementById('nightStarsCanvas');
    if (!canvas || !canvas.getContext) {
        return;
    }
    const context = canvas.getContext('2d');
    draw_sky(context, canvas.width, canvas.height, imageBackgroundColor)
}


export const setCalculationData = ({
                                       dateTime,
                                       showingConstellationLines,
                                       showingConstellationLabels,
                                       showingStarNames,
                                       longitude, latitude,
                                       showingDeepSkyObjects, imageBackgroundColor, nightStarColor
                                   }) => {

    let n = dateTime;
    if (isNaN(n)) {
        alert('Your browser doesn\'t think\n\'' + dateTime.toDateString() + '\'\nis a valid date.');
        // set_user_obs();
        return
    }
    let d = new Date(n);
    now.setDate(d);
    if (longitude >= -180 && longitude < 360) {
        now.setLonDegrees(longitude);
    }

    if (latitude >= -90 && latitude <= 90) {
        now.setLatDegrees(latitude);
    }

    ck_starlabels = showingStarNames;
    ck_conlabels = showingConstellationLabels;
    ck_dsos = showingDeepSkyObjects;
    ck_conlines = showingConstellationLines;

    refreshCanvas({
        imageBackgroundColor: imageBackgroundColor,
        nightStarColor: nightStarColor
    })
};


export function createNightStars(props) {
    STAR_COLOR = props.nightStarColor;
    init_stars(star);
    //init_dsos(dso);
    init_planets(planet);
    now = new Observer();
    setCalculationData(props);
    refreshCanvas({
        imageBackgroundColor: props.imageBackgroundColor,
        nightStarColor: props.nightStarColor
    })
}

function setGeoPos(geopos) {
    now.setLatDegrees(geopos.coords.latitude);
    now.setLonDegrees(geopos.coords.longitude);
    // set_user_obs();
    refreshCanvas()
}

function errGeoPos(error) {
    alert('Geolocation error ' + error.code + ': ' + error.message)
}
export function getGeoPos() {
    if (navigator.geolocation)
        navigator.geolocation.getCurrentPosition(setGeoPos, errGeoPos)
}
